.footer{
    margin-top: 50px;
}
.divContainEmail{
    width: 100%;
    height: auto;
    padding: 10px 0px 10px 0px;
    background-color: var( --background_slip);
    display: block;
    margin: 0 auto;
    text-align: center;
}
.divContainEmail h1{
    font-size: 18px;
}
.rowTest{
    display: block;
    margin: auto auto;
    text-align: left;
} 
.rowTest a{
   padding: 0px 40px 0px 0px;
}
.rowTest a:hover{
    color: black;
 }
.rowTest img{
   width: 80px;
 }
.footerSocai #iconsSocai{
    font-size: 30px;
    transition: all 30ms;
    margin: 0px 5px 0px 5px;
}
.footerSocai #iconsSocai:hover{
    transition: all 50ms;
}
.footerSocai{
    float:right;
}
.rowTest h1{
    text-align: left;
}

.divContainEmail #form_join_us:focus{
    outline: none;
}
.footest{
    background-color: aliceblue;
}
.navFooter{
    margin-top: 50px;
    font-weight: 300;
}
.navFooter h2{
    font-family: var(--fontFamily_Ork);
    font-size: 18px;
    margin-bottom: 10px;
    text-align: left;
}
#linkInFooter h1{
    font-weight: 300;
    padding-top: 5px;
    font-size: 14px;
    color: #888888;
    text-decoration: none;
    text-align: left;
}
#linkInFooter h1:hover{
    transition: all 1s;
    color: deepskyblue;
    text-decoration: none;
    cursor: pointer;
}
#linkInFooter:hover{
    text-decoration: none
}
.navFooter p{
    text-align: left;
    font-size: 14px;
    color: #888888;
}
.navFooter h3{
    color: black;
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    line-height: 15px;
}
.navFooter span{
    color: #888888;
    text-align: left;
    font-size: 13px;
}
.divLeft{
    float: left;
}
.divLeft h1{
    color: #888888;
    display: inline;
    font-size: 12px;
    margin: auto auto;
    text-align: center;
}
.footest .divLeft #logoFooter{
    width: 50px;
}
.footest{
    background-color: beige;
}
.divRight{
    float: right;
}
.imagePaymentCom{
    width: auto;
    height: 30px;
    margin-top: 10px;
    margin-right: 5px;
}
@media (max-width: 1199px) { 
  
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    #linkInFooter{
        margin: 0px;
        padding: 0px;
    }
    #linkInFooter h1{
        padding: 0px;
    }
    .div_contant_this h3{
        word-wrap: break-word;
    }
}
@media (max-width: 575px){
    .link_app{
        display: inline-block;
    }
    .footerSocai{
        display: none;
    }
    .div_group_category{
        display: none;
    }
    .div_about_this{
        display: none;
    }
    .div_how_to_use_this{
        padding: 5px 0px;
        margin: 0px;
    }
    .div_how_to_use_this #linkInFooter{
        margin: 0px;
        padding: 0px;
        position: static;

    }
    .div_contant_this{
        padding: 0px;
    }
    .footest .divLeft #logoFooter{
        width: 30px;
    }
}