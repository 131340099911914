#btnHide{
    border: 0px;
    color: blue;
    background-color: white;
}

#contiune , #viewCart{
    border: 0px;
    width: 90%;
    margin: 10px;
    padding: 20px;
    background-color: #ffeeb6fd;
}
#contiune:hover , #viewCart:hover{
    border: 0px;
    width: 90%;
    margin: 10px;
    padding: 20px;
    background-color: rgb(3, 169, 245);
}
#iconCart{
    color: rgb(3, 169, 245) ;
    font-size: 200px;
    position: relative;
}

.ShowRecommandProduct{
    border-top:1px solid rgb(3, 169, 245);
    width:100%;
    margin-top:20px
}
.ShowRecommandProduct label{
    font-size: 20px;
    float: left;
}
/* /-------------------------------------------------------- */
#inputName,#numberPhone{
    width: 100%;
    margin-top: 20px;
    padding:10px;
}
.choise{
    width: 100%;
    padding-top:10px;
    text-align: left;
}
.choise .btnChoiseProvide{
    width:100%;
    background-color: #ffeeb6fd;
    margin-top: 5px;
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:2px 10px 2px 10px;
    border:0px
}
.choise .btnChoiseProvideClicked{
    width:100%;
    background-color: rgb(3, 169, 245);
    margin-top: 5px;
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:2px 10px 2px 10px;
    border:0px
}
.choise label{
    margin-top: 20px;
}
.btnTypeBuilding{
    background-color: #ffeeb6fd;
    margin-top: 5px;
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:5px 10px 5px 10px;
    border:0px;
    margin-left: 18px;
}
.btnTypeBuildingClicked{
    background-color: rgb(3, 169, 245);
    margin-top: 5px;
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:5px 10px 5px 10px;
    border:0px;
    margin-left: 18px;
}
#btnFinishAddress{
    border-radius: 5px;
    background-color:rgb(3, 169, 245);
    padding:10px 10px 10px 10px;
    width: 100%;
    border: 0px;
    box-shadow: 0px 0px 2px #ffeeb6fd;
    margin-top: 20px;
}
.receiptOneProduct {
    text-align: left;
    padding: 0px;
    margin:0px;
}
.receiptOneProduct img{
    width:120px;
}
#img_logo_receipt{
    width: 100%;
}
#buttonDelete{
    margin-left: 10px;
    padding:2px 30px 0px 30px ;
    background-color:#ffeeb6fd;
    border: 0px;
    border-radius: 5px;
}

#buttonEdit{   
    margin: 0px;
    padding:2px 30px 0px 30px ;
    background-color:#ffeeb6fd;
    border: 0px;
    border-radius: 5px;
}
#buttonDelete:hover{
    border-radius: 5px;
    margin-left: 10px;
    padding:2px 30px 0px 30px ;
    background-color:rgb(253, 140, 140);
    border: 0px;
}
#buttonEdit:hover{   
    border-radius: 5px;
    margin: 0px;
    padding:2px 30px 0px 30px ;
    background-color:rgb(3, 169, 245);
    border: 0px;
}
.selectedAdd{
    background-color:rgb(3, 169, 245);
}
/* /--------------------------------------receipt */
#editAddress{
    text-align: left;
    padding: 0px;
    border: 0px;
    background-color: #fbf5df00;
    text-decoration: underline;
}
.hearderReceipt{
    margin: 0px;
    padding: 0px;
    display:flex;
    justify-content:left;
    align-items:center;
}
.imageProductReceipt{
    width: 120px;
}
.tableProductsReceipt{
    border-bottom:2px solid gainsboro ;
    text-align: center;
   margin-top: 50px;
}
#btnPaymentNow{
    margin: 20px 0px 20px 0px;
    width: 100%;
    padding: 5px;
    border: 0px;
    font-size: 18px;
    border-radius: 5px;
    background-color:#ffeeb6fd;
}
.tableTotalReceipt {
    padding-right: 25px;
    float: right;
}
.tableTotalReceipt .title{
    width: auto;
    padding: 20px;
}
.tableTotalReceipt .money{
    padding: 20px;
    width: auto;
}