
#imageSHOWPRODUCT{
    width: 100%;
    border-radius: 10px 0px 0px 10px;
}
.count_detail_dailog_add_to_cart button{
    margin: 0;
    padding: 0;
    font-size: 16px;
    width: 50px;
    height: 100%;
    background-color: whitesmoke;
    padding: 10px;
    border: 0px;
}
.count_detail_dailog_add_to_cart{
    padding: 10px 0px;
}
.count_detail_dailog_add_to_cart h1{
    font-weight: bolder;
    width: 50px;
    margin: 0;
    font-size: 16px;
    padding: 10px;
    background-color: whitesmoke;
}
.name__product{
   font-size: 16px; 
   text-align: left;
}
#btnFinished_dai_add_cart{
    width: 100%;
    padding:10px 0px;
    border:0px;
    display: inline;
    background-color: var(--background_oneCart);
}
#close_dailog_addtocart{
    width: 50%;
    padding:10px 0px;
    border:0px;
    display: inline;
}
.cotroler_btn_2_in_addTocart_dailog{
    width: 100%;
    display: inline-block;
    padding: 10px 0px;
    }
#btnFinished_dai_add_cart:focus{
    outline: 0px;
}
.block_show_sku{
    padding:5px 0px;
    text-align: left;
}
.block_show_sku button{
    border: 0px;
    margin:5px 5px;
    font-size: 14px;
}
.modal_add_to_dai_cart{
    padding:20px
}
.control_show_sku h4{
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    border-bottom: 1px dotted gray;
    padding:0px 0px;
    margin:0px 10px;
}

.price_dai_add_cart h6{
    font-size: 16px;
    padding:5px 0px;
    text-align: left;
    border-bottom: 1px dotted gray;
    border-top: 1px dotted gray;
}
.price_dai_add_cart h6 span{
    margin:0px 10px;
    font-size: 20px;
    color: red;
}

.control_show_sku_dailog_addTocart{
    text-align: left;
}
.control_show_sku_dailog_addTocart h5{
    font-size: 16px;
    display: inline;
    display: none;
}


.totalSKUPrice_dailog_add_tocart{
    padding: 5px 0px;
}
.totalSKUPrice_dailog_add_tocart h1{
    text-align: left;
    font-size: 16px;
    margin-top:20px;
}
.totalSKUPrice_dailog_add_tocart h6{
   font-family: none;
   font-size: 16px;
   text-align: left;
}
.totalSKUPrice_dailog_add_tocart h1 span{
    font-size: 20px;
    color: red;
}

.detail_block_price_dailog_addCart{
    padding:5px 0px;
    text-align: left;
}
.detail_block_price_dailog_addCart h1{
    display: inline;
    font-size: 16px;
    color:gray;
    font-weight: bolder;
    margin-left: 20px;
    text-decoration-line: line-through;
}
.detail_block_price_dailog_addCart h2{
    display: inline;
    font-size: 20px;
   
}
.detail_block_price_dailog_addCart h2 span{
    display: inline;
    color:red;
}
.name_show_prod_dailog_addCart{
    text-align: left;
    font-size: 18px;
    text-decoration-line: underline;
}
.choise_sku_dailog_add_tocart{
    padding: 0px;
}
#image_SKU_PRODUCT{
    width: 30px;
    margin-right: 10px;
    border-radius:5px;
}
.oneSKU_dailog_addTocart p{
    display: inline-block;
    font-size: 14px;
    margin: 0px;
}
.btnLoopSKUClicked_addtocart{
    padding:2px 10px;
    background-color: rgb(3, 169, 245);
    margin: 3px;
    color:rgb(39, 39, 39);
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    font-size: 12px;
    border: none;
}
.btnLoopSKU_addtocart{
    padding:2px 10px;
    border: none;
    background-color: #ffeeb6fd;
    margin: 3px;
    display: inline-block;
    outline: none;
    font-size: 12px;
}
.dialog_add_data_one_cart_border{
    padding:0px 10px;
    border-radius: 0px 10px 10px 0px;
}
.model_custome_width{
    min-width: 767px;
}
@media (max-width: 1199px) { 
    .model_custome_width{
        min-width: 767px;
    }
}
@media (max-width: 991px){
    .model_custome_width{
        min-width: 575px;
    }
}
@media (max-width: 767px){
    .model_custome_width{
        min-width: 475px;
    }
    #imageSHOWPRODUCT{
        width: 100%;
        border-radius: 10px 0px 0px 10px;
    }
    .name__product{
       font-size: 14px; 
    }
    .control_show_sku h4{
        font-size: 14px;
    }
    
    .price_dai_add_cart h6{
        font-size: 14px;
    }

        .price_dai_add_cart h6 span{
        font-size: 16px;
     }
    
    .control_show_sku_dailog_addTocart h5{
        font-size: 14px;
    }
    .totalSKUPrice_dailog_add_tocart h1{
        font-size: 14px;
    }
    .totalSKUPrice_dailog_add_tocart h6{
       font-size: 14px;
    }
    .totalSKUPrice_dailog_add_tocart h1 span{
        font-size: 16px;
    }
    .detail_block_price_dailog_addCart h1{
        font-size: 14px;
    }
    .detail_block_price_dailog_addCart h2{
        font-size: 14px;
       
    }
    .name_show_prod_dailog_addCart{
        font-size: 14px;
     }
    .oneSKU_dailog_addTocart p{
        font-size: 13px;
     }
    .btnLoopSKUClicked_addtocart{
        font-size: 14px;
    }
}
@media (max-width: 575px){
    .model_custome_width{
        min-width: 90%;
    }
}