.about_page{
    text-align: center;
    padding: 10% 0px;
}
.about_page #logo_about_page{
    width: 200px;
}
.about_page p{
    line-height: 1.8em;
    font-size: 18px;
    text-indent: 50px;
    width: 100%;
    padding: 20px 10%;
    text-align: center;
}
.about_page h6 span{
    font-size: 25px;
    padding: 0px 10%;
    width: 100%;
    text-align: right;
    display: inline-block;
}
@media (max-width: 1199px) { 
  
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    .about_page #logo_about_page{
        width: 150px;
    }
    .about_page p{
        line-height: 1.8em;
        font-size: 16px;
        text-indent: 50px;
        width: 100%;
        padding: 20px 20px;
        text-align: center;
    
        background-color: #6d4c41;
        background-color:#9c786c;
        background-color: #40241a;
    }
}