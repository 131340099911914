
.groun_shOw_chat{
  padding-top:60px;
  height: 100vh;
}
.test_ling{
  height: 80vh;
  width:30%;
  overflow-y: scroll;
  position:fixed;
  left: 20px;
  text-align: left;
}
.hello_chatint{
  padding-top:60px;
}
._lala{
 padding-bottom: 80px;
}
.header_chating{
  position:fixed;
  right:0px;
  position: fixed;
  padding:5px 0px;
  text-align: center;
  width:100%;
  z-index: 200;
  background-color: white;
}
.header_all_user_chated{
  text-align: right;
  width:100%;
  padding:5px 30px;
}
.header_chating_ing{
  text-align: left;
  float:left;
}
.header_chating_ing p{
  display: inline;
  padding:0px 20px;
}

.im_pf_sh{
  width: 50px;
}
.chat-bubble {
  padding: 10px 10px;
  background: #eee;
  margin: 10px 30px;
  border-radius: 9px;
  position: relative;
  animation: fadeIn 1s ease-in;
  word-break: break-all !important;
  text-align: left !important;
  padding: 15px 10px !important;}
  .chat-bubble--left{
    background-color: #eceff1;
    margin:10px 0px !important;
  }
  .chat-bubble--right{
    margin: 10px 0px !important;
  
    background-color: #a8c5dd;
  }
  .display_statur{
    position: relative;
  }
  .display_statur span i{
    font-size: 10px;
  }
  .display_statur p{
    width: 100%;
    text-align: right;
    position: absolute;
    font-size: 12px;
    color: gray;
    margin: 0px;
    padding: 0px !important;
    bottom: -15px;
    right: 0px;
  }
  .fix_inpunt{
    margin-left: -20px;
    display:block;
    align-items: baseline;
    padding: 15px 5px;
    align-items: center;
    margin-top: 0px;
    bottom: 0px;
    position: fixed;
    width: 66.6%;
    background-color: wheat;
}
.fix_inpunt input{
    width: 80%;
    border: 0px;
    border-radius: 30px;
    padding:0px 10px;
}

.fix_inpunt button{
    width: 20%;
    background-color:wheat ;
    border: 0px;
    padding: 10px 0px;
}
.titleShop_all_chat img{
  width: 80px;
  height: 80px;
}
.titleShop_all_chat p{
  font-size: 12px;
  line-height:1.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
}
#id_remove_show_all_shop{
  position: absolute;
  right: 10px;
  top: 6px;
}
.one_say_message{
  cursor: pointer;
}
.media-body{
  padding-left: 10px;
}
@media (max-width: 1199px) { 
  .small_chatning{
    display: none;
  }

  .header_all_user_chated{
    width:100%;
    padding:5px 18px;
  }
  .im_pf_sh{
    width: 40px;
  }
  .one_say_message h6{
    font-size: 16px;
  }
  .one_say_message small{
    font-size: 10px;
  }
  .one_say_message p{
    font-size: 14px;
  }
  .fix_inpunt{
    margin-left: -15px;
    padding: 5px 5px;
  }
}
@media (max-width: 991px) { 
  .small_chatning{
    display: none;
  }
 
  .test_ling{
    height: 75vh;
    width:30%;
    left: 10px;
   }
  .header_all_user_chated{
    width:100%;
    padding:5px 18px;
  }
  .im_pf_sh{
    width: 40px;
  }
  .one_say_message{
    padding:5px;
  }
  .one_say_message h6{
    font-size: 14px;
  }
  .one_say_message small{
    font-size: 8px;
  }
  .one_say_message p{
    font-size: 12px;
  }
  .fix_inpunt{
    margin-left: -15px;
    padding: 5px 5px;
  }
  .fix_inpunt input{
    border-radius: 20px;
    padding:0px 10px;
    font-size: 12px;
}
.fix_inpunt button{
  width: 20%;
  background-color:wheat ;
  border: 0px;
  padding: 5px 0px;
  font-size: 14px;
  }
  .hello_chatint{
    padding-top:40px;
    padding-bottom: 20px;
  }
  .header_chating{
    margin-top: -5px;
  }
}

@media (max-width: 767px){
  .test_ling{
    height: 90%;
    width:38%;
    left: 5px;
    padding-bottom: 80px;
  }
  .one_say_message h6{
    font-size: 12px;
  }
  .fix_inpunt input{
    width: 70%;
    padding:0px 10px;
}

.fix_inpunt button{
    padding: 5px 0px;
}
.chat-bubble {
  margin: 2px 10px;
  padding: 5px 10px  15px  10px!important;
  padding-bottom: 15px;}
  .chat-bubble--left{
    margin:2px 0px !important;
  }
  .chat-bubble--right{
    margin: 2px 0px !important;
  }
}