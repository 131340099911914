.classPrivatcy{
    background-color: gray;
}
.classPrivatcy #logo_inPrivatcy{
    width: 120px;
    
    margin-left: auto;
    margin-right: auto;
}
.classPrivatcy .contant_privatcy{
    padding: 20px 20px;
}
.classPrivatcy .contant_privatcy h1{
    text-align: center;
    margin: 10px 0px;
    font-size: 25px;
    font-family: var(--fontFamily_Ork);
}
.classPrivatcy .contant_privatcy p{
   line-height: 1.8em;
    font-size: 16px;
}
.contant_privatcy p .show_title_privatcy{
    font-size: 18px;
    font-family: var(--fontFamily_Ork);
}




.contant_privatcy_screen{
 
}
.contant_privatcy_screen #logo_inPrivatcy{
    width: 120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.contant_privatcy_screen .contant_privatcy{
    padding: 20px 20px;
}
.contant_privatcy_screen .contant_privatcy h1{
    text-align: center;
    margin: 10px 0px;
    font-size: 25px;
    font-family: var(--fontFamily_Ork);
}
.contant_privatcy_screen .contant_privatcy p{
   line-height: 1.8em;
    font-size: 16px;
}
.contant_privatcy_screen p .show_title_privatcy{
    font-size: 18px;
    font-family: var(--fontFamily_Ork);
}

