.div_store_stories{
    padding:5px;
    height: auto;
    width: 100%;
}
.image_store_stories{
    width: 100%;
    position: relative;
}
.slide_background #prev_store_stories_home_silde{
    width: auto;
    height: auto;
    background-color: rgba(128, 128, 128, 0.42);
    z-index: 1;
    left: 0 !important;
    padding:10px 20px;
}
.slide_background #next_store_stories_home_slide{
    width: auto;
    height: auto;
    background-color:  rgba(128, 128, 128, 0.42);
    z-index: 1;
    padding:10px 20px;
    right: 0;
}
.image_store_stories_pf_store{
    width: 150px;
    height: 150px;
    position: absolute;
    top: 20px;
    border-radius: 50%;
    margin-left:10px;
}
.image_store_stories{
    width: 100%;
}
@media (max-width: 1199px) { 
    .div_store_stories{
        padding:5px;
        height: auto;
        width: 100%;
    }
    .image_store_stories_pf_store{
        width: 80px;
        height: 80px;
    }
}
@media (max-width: 991px){
    .image_store_stories_pf_store{
        width: 70px;
        height: 70px;
    }
}
@media (max-width: 767px){
    .div_store_stories{
        padding:5px;
        width: 100%;
    }
}
@media (max-width: 575px){
    .div_store_stories{
        padding:5px;
        width: 100%;
    }
    .image_store_stories_pf_store{
        width: 30px;
        height: 30px;
        position: absolute;
        top: 10px;
        margin: 5px;
    }
    .image_store_stories{
        position: relative;
    }
    .slide_background #prev_store_stories_home_silde{
        width: auto;
        height: auto;
        background-color: rgba(128, 128, 128, 0.42);
        z-index: 10;
        left: 0 !important;
        padding:5px 10px;
    }
    .slide_background #next_store_stories_home_slide{
        width: auto;
        height: auto;
        background-color:  rgba(128, 128, 128, 0.42);
        z-index: 10;
        padding:5px 10px;
        right: 0;
    }
}