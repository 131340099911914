
.pageShop .menuShop{
    padding-top: 30px;
    width:13%;
    position:fixed;
    z-index:1
}
.oneShop{
    border-radius: 5px;
    padding: 20px;
    margin-top: 10px;
    box-shadow: 0px 1px 3px rgb(144, 144, 144);
    background-color: rgb(224, 224, 224);
}
.groupProfileStore{
    float: left;
}
.groupProfileStore h1{
    padding-left:20px ;
    font-size: 12px;
    color: gray;
    display: block;
    margin: auto auto;
    text-align: left;
}
.groupProfileStore h1 .nameStore{
    cursor: pointer;
    font-size: 18px;
    color: black;
}
.groupProfileStore h1 #follwer{
    font-size: 12px;
}

.groupProfileStore img{
   width: 100px;
   height: 100px;
   border-radius:10px; 
   cursor: pointer;
}
.buttonFavorite {
    float: right;
}
.buttonFavorite button {
    border: 0px;
    background: none;
}
.buttonFavorite button:focus {
    outline: none;
}



.stories{
    /* margin-top: 10px; */
    height: auto;
}
.stories .storieProducts{
    width: 100px;
}
#show_store {
    border: 0px;
    padding: 10px;
    width: 100%;
    text-align: center;
    background-color: #ffcf40;
    border-radius: 5px;
    padding: 10px 0px;
    font-size: 20px;
    }
.control_button_log_to_shop{
    padding:20px 0px
}
#left-tabs-example-tab-shops,
#left-tabs-example-tab-favorite{
    color:black
}
.menuShop .nav-pills .active{
    background-color: #fbc71bfd;
}

.buttonFavorite button:focus,#show_store:focus {
    outline: none;
}
.control_shops{
    padding:0px 0px 20% 0px;
}

.not_shop_{
    padding:30% 0px;
    width: 100%;
}
.not_shop_ h4{
    color:black;
    font-size: 25px;
}
/* ................................... */
.lala{
    box-shadow: -1px -1px 5px rgba(128, 128, 128, 0.432);
    background-color:white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
    text-align: center;
    min-height: 100%;
    padding:0% 0%;
    -webkit-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
.nav-link{
    color: black;
}

#nav_shop,#nav_Favorite,#nav_search{
    display: none;
    color: black;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}
.left_tab .nav .active{
    color:rgb(131, 1, 1);
    background: none;
    text-decoration: underline;
    border-top:0px so0id gray;
    box-shadow: -0px 0px 5px gray;
    border-radius: 50px 0px 0px 50px;
    margin-left: 10px;
}

.class_col p{
    font-size: 20px;
    padding:10px 10px;
    margin:0px;
}
#icon_favor_shop,
#icon_Shop_s,
#icon_search{
    margin:20px 0px;
    font-size: 40px;
    transform: rotate(180deg);
    -webkit-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
}

/* ---------------------------------------- */
.Container_custome{
    max-width: 100%;
    padding:0px 8%;
    padding-top: 0px;
    height: auto;
    margin:0px;
}
.div_control_shop{
    /* padding: 10px; */
    background-color: rgb(255, 255, 255);
    overflow: hidden;
    height: auto;
    text-align: left;
    border-radius: 5px;
    position: relative;
    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.76);
}
.oneShop_edit{
    padding:20px 5px;
    margin:0px;
}

.div_control_shop img{
    width: 60px;
    height: 60px;
    padding:0px;
    margin: 0px;
    border-radius: 50%;
}
.div_control_shop button{
    border: 0px;
    float: right;
}
.div_control_shop button:focus{
    outline: 0px;
}
#iconFavoritebtnClick{
    float: right;
    color: brown;
    font-size: 40px;
    margin-top: 20px;
}
#iconFavoritebtn{
    float: right;
    color: black;
    font-size: 40px;
    margin-top: -50px;
    transition: all 2s;
}
.oneShop_edit:hover #iconFavoritebtn{
    margin-top: 20px;
    transition: all 500ms;
}
.div_Image{
    display: inline-block;
    width:100%;
}
.div_Image img{
    float: left;
}
.div_Image h1{
    display: block;
    word-wrap: break-word;
    padding:20px 10px;
    font-size: 14px;
    float: left;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    /* display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
    width: 50%;
    background-color: royalblue; */
}
.div_top_pro{
    padding:10px;
    background-color:#FFEEB6FD;
}
.div_top_pro button{
    right: 10px;
    position: absolute;
    background: none;
    border-bottom: 2px solid #e9af03;
}
.div_top_pro a:hover{
    color: black;
    text-transform: none;
}
.data_story_shop{
    padding:30px 10px 10px 10px;
    /* background-color: red; */
}
.data_story_shop .slick-slider .slick-prev{
    margin: 0px;
    padding:0px;
    background-color: red;
    margin-right: -20px;
}
.btn_go_to_shop_die{
    padding:30px 30px;
    width: 100%;
    margin-bottom: 30px;
}
.btn_go_to_shop_die button{
    background-color: #f8ce4e;
    border-radius: 0px;
    border: 0px;
    width: 100%;
    padding:10px 0px;
}
#prev_store_stories,#next_store_stories{
    width: 50px;
    text-align: center;
    height: 30px;
    background-color: rgba(116, 116, 116, 0.582);
    z-index: 1;
}
.data_story_shop .slick-dots li button:before{
    font-size: 10px;
    color:black
}
#prev_store_stories:hover,
#next_store_stories:hover{
    width: 50px;
    text-align: center;
    height: 30px;
    background-color: rgba(66, 54, 54, 0.26);
    z-index: 1000000;
}
.get_shop_more{
    text-align: center;
    width: 100%;
}
.loadingSmall{
    text-align: center;
    display: inline-block;
    width: 100%;
    padding:32px 200px;
}

.control_shops_edit #btn_control_shops_more{
    padding:10px 200px;
    border:0px;
    margin:20px 0px;
}
.control_shops_edit #btn_control_shops_more:hover{
   background-color: #fbc71bfd;
}

.control_shops_edit #btn_control_shops_more:focus{
    outline: none;
 }
 .nodataMore{
     text-align: center;
     width: 100%;
     font-size: 20px;
     padding:30px 0px;
 }
 .nodataMore h1{
    font-size: 25px;
 }
 .input_search_shop{
     display: inline-block;
     width: 100%;
     position: relative;
 }
 #txt_search_shop{
     padding:10px 130px;
     width: 99%;
     border: 0px;
     border-bottom: 1px solid gray;
 }
 #button_clear_search{
    border: 0px;
    right:10px;
    top: 5px;
   position: absolute;
 }
 #button_edit_search{
     border: 0px;
     left:10px;
     top: 5px;
    position: absolute;
 }
 #label_show_search_shop{
    border: 0px;
    left:10px;
    top:10px;
    position: absolute;
     color: gray;
 }

 #btn_get_back_searched{
     border: 0px;
     width: 100%;
     margin:10px 0px;
     padding:10px 0px;
     background-color: #f8ce4e;
 }
 #btn_get_back_searched_dis{
    border: 0px;
    width: 100%;
    margin:10px 0px;
    padding:10px 0px;
    background-color: #fffaec;
 }
 #button_clear_search:focus{
   outline: none;
 }


 @media (max-width: 1199px) { 
    #icon_favor_shop,
    #icon_Shop_s,
    #icon_search{
        margin:10px 0px;
        font-size: 30px;
    }
    .left_tab .nav .active{
        margin-left: 5px;
    }
    .nav-link{
        padding: 0px 10px;
    }
    #iconFavoritebtnClick{
        float: right;
        color: brown;
        font-size: 30px;
        margin-top: 20px;
    }
    .div_Image h1{
        padding:20px 10px !important;
        font-size: 14px;
    }
    .nameStore{
    }
    .nodataMore h1{
        font-size: 18px;
     }
}
@media (max-width: 991px){
    .class_col{
        display: none;
    }
    .Container_custome{
        margin: 0px;
       padding:0px 10px;
        padding-left: 55px;
    }
    #iconFavoritebtnClick{
        float: right;
        color: brown;
        font-size: 30px;
        margin-top: 20px;
    }
    .nameStore{
        line-height: 2px;
    }
    .div_Image{
    display: inline-block;
    width:100%;
    }
    .div_Image h1{
        display: block;
        word-wrap: break-word;
        padding:20px 10px;
        font-size: 14px;
        float: left;
        text-align: left;
        line-height:1.4em;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        width: 60%;
    }
    #iconFavoritebtn{
        float: right;
        color: black;
        font-size: 30px;
        margin-top: 20px;
        transition: all 2s;
    }
    .nodataMore h1{
        font-size: 16px;
     }
}
@media (max-width: 767px){
    .not_shop_{
        padding:30% 0px;
        width: 100%;
    }
    .Container_custome{
        margin: 0px;
       
        padding:0px 10px;
        padding-left: 55px;
    }
    .not_shop_ h4{
        color:black;
        font-size: 18px;
    }
    .nodataMore h1{
        font-size: 14px;
     }
}
@media (max-width: 575px){
    .Container_custome{
        padding:0px 5px;
        padding-left: 55px;
    }
    .class_col{
        display: none;
    }
    .control_shops_edit #btn_control_shops_more{
        font-size: 14px;
        padding:10px 20px;
        border:0px;
        margin:20px 0px;
    }
    .loadingSmall{
        padding: 0px !important;
    }
}