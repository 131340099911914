.Login_with_socai{
    padding: 20px 0px 0px 0px;
}
.Login_with_socai .login_facebook{
    width: 30px;
    margin: 0px 10px;
}
.Login_with_socai .login_google{
    width: 30px;
    margin: 0px 10px;
}
.Login_with_socai .div_icone{
    display: inline;
}

.background_login{
    background-color: var(--background);
    width: 50%;
    border: 1px solid gray;
    margin-top: 10%;
    padding:50px 0 50px 0;
    border-radius: 50px;
}
.lbl_create_onInput{
    text-align: left;
    min-width: 350px;
    margin: 20px 0px 5px 0px;
}
#image_logo{
    width: 180px;
    height: auto;
    padding: 0;
    margin: 0;
}
.txtinput{
    min-width: 350px;
    font-size: 16px;
    border-radius: 10px;
    border: 1px solid black;
    padding: 10px;
}
.btn_continue{
    border: 0px;
    min-width:350px;
    padding: 10px 0px;
    font-size: 18px;
    background-color: var(--background_button);
    margin:20px 0px 0px 0px;
    border-radius:10px ;
    color: black;
}
#error_login_icon{
    color:var(--error);
    font-size: 50px;
}
.metro{
    border: 0px;
    background: none;
}
.btn_loginGoogle{
    background-color: red;
}
.google_login{
    color: #000;
    padding: 0px !important;
    background: none !important;
    display:inline-block !important;
    box-shadow: none !important;
}

.loadingSmall_login{
    padding: 15px 0px;
}


@media (max-width: 1199px) { 
    
    .background_login{
    margin-top: 5%;
    }
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    .Login_with_socai{
        padding: 10px 0px 0px 0px;
    }
    .Login_with_socai .login_facebook{
        width: 25px;
        margin: 0px 5px;
    }
    .Login_with_socai .login_google{
        width: 25px;
        margin: 0px 5px;
    }
    .show_title_socail{
        font-style: 10px;
        margin-right: 30px;
    }
    .show_title_socail p{
        border: 0px;
        font-style: 10px;
        margin-bottom: 0px;
    }

    .background_login{
        background-color: var(--background);
        min-width:80%;
        max-width: 90%;
        border: 1px solid gray;
        margin-top: 10%;
        padding:50px 0px;
        border-radius: 20px;
    }
    .lbl_create_onInput{
        text-align: left;
        padding: 0px 5px;
        min-width: 70%;
        max-width: 70%;
    }
    #image_logo{
        width: 150px;
    }
    .txtinput{
        min-width: 70%;
        max-width: 70%;
        font-size: 16px;
        border-radius: 10px;
        border: 1px solid black;
        padding: 10px;
    }
    .btn_continue{
        min-width: 70%;
        max-width: 70%;
    }
    #error_login_icon{
        font-size: 40px;
    }
    .btn_loginGoogle{
        background-color: red;
    }
    .google_login{
        color: #000;
        background: none !important;
        display:inline-block !important;
        box-shadow: none !important;
    }
}
@media (max-width: 575px){
    .Login_with_socai{
        padding: 10px 0px 0px 0px;
    }
    .Login_with_socai .login_facebook{
        width: 25px;
        margin: 0px 5px;
    }
    .Login_with_socai .login_google{
        width: 25px;
        margin: 0px 5px;
    }
    .show_title_socail{
        font-style: 10px;
        margin-right: 30px;
    }
    .show_title_socail p{
        border: 0px;
        font-style: 10px;
        margin-bottom: 0px;
    }

    .background_login{
         min-width:100%;
        max-width: 100%;
    }
    .lbl_create_onInput{
        min-width: 90%;
        max-width: 90%;
    }
    #image_logo{
        width: 150px;
    }
    .txtinput{
        min-width: 90%;
        max-width: 90%;
    }
    .btn_continue{
        min-width: 90%;
        max-width: 90%;
        font-size: 18px;
        padding: 10px 0px;
    }
    #error_login_icon{
        font-size: 40px;
    }
    .google_login{
        color: #000;
        background: none !important;
        display:inline-block !important;
        box-shadow: none !important;
    }
    .rith____ input{
        width: 40px !important;
        margin: 0px 2px !important;
        height: 40px !important;
        font-size: 18px !important;
        border-radius: 15px !important;
    }
}
@media (max-width: 320px){
    .rith____ input{
        width: 10px !important;
    }
}