.detailShop .Container_custome{
    /* max-width: 100%;
    padding:0px 8% 0px 350px;
    padding-top: 0px;
    height: auto;
    margin:0px; */
}
    .left_tab_shop{

}
#btn_control_shops_more_propduct_shop{
    padding:10px 100px;
    border:0px;
    margin:20px 0px;
    font-size: 18px;
    background-color: rebeccapurple;
}

.lili{
    padding:30px 0px;
    background-color:whitesmoke;
    margin:30px 0px;
    box-shadow: -1px -1px 10px rgba(29, 29, 29, 0.432);
    border-radius: 20px;
    /* position: fixed; */
    /* background-color:whitesmoke ;
    padding:50px 0px;
    display: none; */
    /*
    background-color:whitesmoke;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 350px;
    display: inline-block;
    text-align: center;
    height: 100%;
    padding:0px 10px;
    padding-top:10%; */
}
.nav_item_profile_shop_clicked{
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0px 0px 2px black;
    font-size: 20px;
    margin:10px 0px;
    width: 100%;
}
#image_profile_Shop{
    background-color: aliceblue;
    width: 100%;
    height: auto;
    border: 1px solid gray;
    border-radius: 20px;
    text-align: center;
    border:1px solid gray;
}
.namme_shop{
    text-align: left;
    padding: 20px 0px;
    margin:0px 30px ;
}
.namme_shop h1{
    word-break: break-all;
    display: inline-block;
    font-size: 25px;
    text-align: left;
}
.namme_shop h2{
    text-align: left;
    font-size: 18px;
}
.c_nav_profile_shop{
    background-color: red;
}
.nav_item_profile_shop h1,
.nav_item_profile_shop_clicked h1{
    font-size: 20px;
    line-height: normal;
    margin:0px;
}
.nav_item_profile_shop{
    display: inline-block;
    border-radius: 5px;
    box-shadow: 0px 0px 2px black;
    font-size: 20px;
    margin:10px 0px;
    width: 100%;
    padding:5px 0px
}
.nav_item_profile_shop_clicked{
    padding:5px 0px;
    background-color: antiquewhite;
}

.loadingSmall_detailShop{
    padding: 25px 0px;
}
.three_button_profile_shop_small{
   display: none;
}
#btnNewChat{
    border: none;
    padding:10px 20px;
    background-color: #a8c5dd;
    border-radius: 2px;
    font-size: 16px;
  }
@media (max-width: 1199px) { 
    .lili {
        border-radius: 10px;
    }
    #btn_control_shops_more_propduct_shop{
        padding:10px 80px;
        font-size: 14px;
    }
    #btnNewChat{
        padding:6px 10px;
        font-size: 14px;
      }
}
@media (max-width: 991px){
    #btn_control_shops_more_propduct_shop{
        padding:10px 60px;
        font-size: 14px;
    }
    #btnNewChat{
        padding:6px 10px;
        font-size: 12px;
      }
}
@media (max-width: 767px){
    #btn_control_shops_more_propduct_shop{
        padding:10px 46px;
        font-size: 12px;
    }
}
@media (max-width: 575px){
    .three_button_profile_shop_big{
        display:none
    }
    .three_button_profile_shop_small{
        display:flex;
        padding:0px !important;
        width:100%;
    }
    .nav_item_profile_shop h1,
    .nav_item_profile_shop_clicked h1{
        font-size: 16px;
    }
    .nav_item_profile_shop{
        display: inline-block;
        border-radius: 3px;
        box-shadow: 0px 0px 2px black;
        margin:10px 0px;
        width: 100%;
        padding:10px 0px
    }
    .nav_item_profile_shop_clicked{
        padding:10px 0px;
        background-color: antiquewhite;
    }
    .namme_shop{
        text-align: left;
        padding: 20px 0px;
        margin:0px 0px ;
    }
    .namme_shop h1{
        display: inline-block;
        font-size: 18px;
        text-align: left;
    }
    .namme_shop h2{
        text-align: left;
        font-size: 18px;
    }
    .lili {
        padding:5px 0px;
        margin:10px 0px;
    }
}

@media (max-width: 417px){
    .nav_item_profile_shop h1,
    .nav_item_profile_shop_clicked h1{
        font-size: 14px;
    }
}
@media (max-width: 380px){
    .nav_item_profile_shop h1,
    .nav_item_profile_shop_clicked h1{
        font-size: 13px;
    }
}
@media (max-width: 343px){
    .nav_item_profile_shop h1,
    .nav_item_profile_shop_clicked h1{
        font-size: 10px;
    }
}