.hearderReceipt {
    display: inline;
    text-align: center;
}
#input_comfirm_pay_end{
    font-size: 20px;
    border: 0px;
    width: 50%;
    padding:10px 0px;
    margin: 0px auto;
    background-color: rgb(200, 200, 200);
    letter-spacing: 1px;
    text-align: center;
}
.class_comfirm_buy_product .modal-header{
    display: block;
}
.hearderReceipt h3{
    font-size: 30px;
    text-align: center;
}
#iconCloseComfirmBuy{
    font-size: 30px;
}
#iconCloseComfirmBuy:hover{
    cursor: pointer;
}
.prodImageComfirmBuy{
    width: 120px;
    height: auto;
    margin: 0px;
    padding: 0px;
}
.tableTotalReceipt td, th {
    background-color: antiquewhite;
  }
.tableTotalReceipt td, td {
    word-wrap: break-word;
  }
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
 
  
.navBuyComfirmBuy{
    position: fixed;
    bottom: 0;
    right: 0px;
    width: 100%;
    background-color: gray;
}
.navBuyComfirmBuy .showTotal{
    padding: 10px;
    background-color: #ffeeb6fd;
    border-radius: 0px 10px 0px 0px;
}
.navBuyComfirmBuy .showTotal h1{
    font-size: 20px;
}
.navBuyComfirmBuy .showTotal span{
    font-size: 30px;
}
.navBuyComfirmBuy #toPay{
    width: 100%;
}
.tableProductsReceiptcomfirmBuy{
    margin-bottom: 50px;
}
.tableProductsReceiptcomfirmBuy span{
    color: red;
    text-align: center;
}
#btnAddAddress{
    font-size: 18px;
    padding: 10px;
    border:0px;
    margin-bottom: 20px;
    color: red;
    font-weight: bolder;
}
.div_caculate{
    font-family: var(--fontFamily_Kantumruy);
    padding: 30px 0px;
    border-top: 1px solid gray;
}
.promoCode{
    padding-top: 20px;
    width: 100%;
}
.div_caculate .showAllTotal{
    padding: 20px 40px;
    text-align: center;
}

.div_caculate .showAllTotal span{
    
   letter-spacing: 2px;
   text-align: right;
   float: right;
   font-family: -apple-system,BlinkMacSystemFont;
   font-size: 20px;
   font-weight: 600;
}
.div_caculate .showAllTotal h6{
    text-align: left;   
    line-height: 25px;
    letter-spacing: 1px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.div_caculate .showAllTotal h5{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.div_caculate .showAllTotal h5 span{
    color:red
}
#btnInputCode img{
    width: 50px;
    margin-top: -10px;
}
#btnInputCode{
    width: 100%;
    border: 0px;
    padding: 10px 10px;
    float: right;
    margin: 0px;
}
#icon_close_promote_code{
    text-align: right;
    position: absolute;
    right: 10px;
    top: 5px;
}
.h1_showTitle_code_promote{
    font-size: 25px;
}
.img_present_input_promote_code{
    width: 100px;
}
#input_code_promote_{
    width: 80%;
    padding: 5px 5px;
    margin: 10px 0px;
    border:0px ;
    border-bottom: 1px solid gray;
    font-size: 20px;
    letter-spacing: 3px;
}
.class_comfirm_buy_product{
    
    overflow: scroll;
    background-color: gray;
    padding-bottom: 200px;
    position: fixed;
}
.comfirmBuy_image_product{
    position: relative;
}
.comfirmBuy_image_product p{
    position: absolute;
    right: 0px;
    font-size: 12px;
    background-color: red;
    color: white;
    padding: 2px 10px;
    border-radius: 5px 0px 5px 0px;
}
.showAllTotal #bad_this_total{
    font-size: 14px;
    color: gray;
    text-decoration: line-through;
}
.product_name_alias{
    text-align: left;
}
.tableProductsReceiptcomfirmBuy .header_resipt{
    background-color: bisque;
    padding: 5px 0px;
    margin: 5px 0px ;
}
.once_rescept{
   marging:5px 0px;
   border-bottom:1px dotted gray ;
   padding: 5px 0px !important;
   }
   .address_user_resiept label span{
       word-break: break-all;
   }
   .once_rescept_small{
       display: none;
   }
   #img_logo_receipt{
       display: none;
   }
@media (max-width: 1199px) { 
    .hearderReceipt h3{
        font-family: var(--fontFamily_Kantumruy);
        font-size: 25px;
    }
    .navBuyComfirmBuy #toPay{
        max-width:100%;
        padding: 15px;
    }
}
@media (max-width: 991px){
    .hearderReceipt h3{
         font-size: 18px;
    }
    .promoCode{
        padding-top: 0px;
    }
    #editAddress{
        color: rgb(255, 110, 110);
    }
    .once_rescept,.header_resipt{
        display: none;
    }
    .once_rescept_small{
        display: flex;
        margin-top: 10px !important;
    }
    .once_rescept_small .comfirmBuy_image_product p{
        top: 0px;
        left: 0px;
        font-size: 12px;
        width: 50% !important;
        padding: 0px !important;
        background-color: red;
        border-radius: 2px 0px 10px 0px;
    }
    .once_rescept_small .prodImageComfirmBuy{
        width: 100%;
        height: auto;
        margin: 0px;
        padding: 0px;
    }
    .once_rescept_small .product_name_alias{
        font-size: 14px;
    }
    .once_rescept_small .product_name_alias span{
        font-size: 16px;
        color: red;
        letter-spacing: 1px;
        line-height: 30px;
    }
    .class_comfirm_buy_product{
    
        overflow: scroll;
        background-color: gray;
        padding-bottom: 100px;
        position: fixed;
    }
    .showAllTotal {
        padding:5px 0px !important
    }
    
    .div_caculate .showAllTotal span{
    font-size: 18px;
     }
    .div_caculate .showAllTotal h6{
        line-height: 20px;
      }
    .div_caculate .showAllTotal h5{
        font-size: 16px;
    }
    .div_caculate .showAllTotal h5 span{
        color:red
    }
    #btnAddAddress{
        font-size: 16px;
        margin-bottom: 10px;
    }
}
@media (max-width: 767px){
    #btnAddAddress{
        font-size:14px;
        margin-bottom: 0px;
        padding:6px
    }
    .class_comfirm_buy_product .modal-header{
        justify-content: stretch;
    }
    .hearderReceipt h3{
        font-size: 18px;
        margin-left: 30px;
   }
   #iconCloseComfirmBuy{
    font-size: 20px;
    }
    #btnInputCode img{
        width: 30px;
        margin-top: -10px;
    }
    #btnInputCode{
        font-size: 16px;
    }
    #img_logo_receipt{
        display:none;
    }
}
@media (max-width: 575px){
    .hearderReceipt h3{
        font-size: 18px;
        padding-top: 0px;
   }
   #iconCloseComfirmBuy{
    font-size: 20px;
    }
    #toPay{
        z-index: 1111111111;
    }
}