#controlButtonBuyFooter{
    display: none;
    position: fixed;
    bottom:0%;
    overflow: hidden;
    width: 100%;
    margin:0px;
    padding:0px;
    background-color: gainsboro;
    z-index: 111;
}
 #controlButtonBuyFooterNotFight{
    display: none;
    height: 80px;
}
.btnTwo_ButtonBuyFooter{
    font-size: 20px;
    padding: 10px 30px 10px 30px;
    margin: auto;
    margin-top: 10px;
    background-color: #ffeeb6fd;
    border: 0px;
}
@media (min-width: 768px) and (max-width: 1024px) {
    
}

@media (min-width: 481px) and (max-width: 767px) {
    #controlButtonBuyFooter{
        display: block;
    }
    #controlButtonBuyFooterNotFight{
        display: block;
        height: 80px;
    }
    .btnTwo_ButtonBuyFooter{
        font-size: 20px;
        padding: 10px 70px 10px 70px;
        margin: auto;
        margin-top: 10px;
        background-color: #ffeeb6fd;
        border: 0px;
    }
}

@media (max-width: 480px) {
    #controlButtonBuyFooter{
        display: block;
    }
    #controlButtonBuyFooterNotFight{
        display: block;
        height: 80px;
    }
    .btnTwo_ButtonBuyFooter{
        font-size: 18px;
        padding: 10px 30px 10px 30px;
        margin: auto;
        margin-top: 10px;
        background-color: #ffeeb6fd;
        border: 0px;
    }
} 