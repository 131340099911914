
.detail_one_address{
    text-align: left;
    /* max-width: 100%; */
    word-wrap: break-word;
}
.modal_add_address__{
    background-color: gray;
}
.btn_2_edit_delete{

}
.oneAddress{
    border-top: 1px solid rgb(173, 173, 173);
    border-bottom: 1px solid rgb(173, 173, 173);
    width: 100%;
    padding: 10px !important;
    word-wrap: break-word;
}
.showAllAddress tbody{
    max-width: 10px !important;
    background-color: red;
}
.modal_add_address__ .modal-body{
    padding: 0px !important;
}
.oneAddress #btn_editAddress,
.oneAddress #btn_deleteAddress
{
    width: 30%;
    padding: 2px 10px;
    float: right;
    font-size: 13px;
    margin: 3px 5px;
    border: 0px;
    border-radius: 2px;
}   
.oneAddress #btn_editAddress{
    background-color: rgb(79, 176, 255);
}
.oneAddress #btn_deleteAddress{
    background-color: rgb(255, 148, 148);
}
#btnAddAddressMore{
    margin: 10px;
    /* width: 25%; */
    font-size: 16px;
    float: right;
    border:0px;
    padding:10px 20px

}
.inputNewAddress{
    padding: 0px 10px;
}
#textArea{
    padding: 10px;
    
    margin:0px 10px;
    width: 97%;
}
#btnFinishAddress_{
    background-color:rgb(79, 176, 255) ;
    padding: 8px;
    border-radius: 5px;
    border: 0px;
    margin:10px 10px;
    width: 97%;
}
#btnCancelAddress{
    padding: 8px;
    border-radius: 5px;
    border: 0px;
    margin:10px 10px;
    width: 97%;
}
@media (max-width: 1199px) { 
  
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    #btnAddAddressMore{
        width: 50%;
        font-size: 14px;
    }
    .oneAddress #btn_editAddress,
    .oneAddress #btn_deleteAddress
    {
        width: 30%;
        padding: 2px 10px;
        float: right;
        font-size: 13px;
        margin: 3px 5px;
        border: 0px;
        border-radius: 2px;
    }   
}