.btnSupport {
   margin-top: 50px;
}
.btnSupport #iconSupport{
    font-size: 50px;
}
.btnSupport button{
    width: 100%;
    border: 0px;
}
.btnSupport h1{
   font-size: 20px;
 }
 .btnSupport h2{
    font-size: 10px;
}
.btnSupport:hover h1{
    font-size: 20px;
    color: blue;
}
.controlCategories{
    padding:50px 0px;
}
.control_threeImage{
    padding: 150px 0px;
}
.div_get_product_more{
    text-align: center;
}
.div_get_product_more #btn_get_more_product{
    padding:10px 200px;
    border:0px;
    margin:20px 0px;
     outline: 0px;
}
.div_get_product_more #btn_get_more_product:focus{
   
}
.control_homeProduct{
    padding:30px 0px
}
#btn_contenue_shopping_home{
    border: 0px;
    padding: 5px 0px;
    font-size: 18px;
    width: 49%;
    margin: 1px;
}
#image_gif_done_added_tocart{
    width: 120px;
}
.modal_added_to_cart h1{
    font-size: 18px;
}
.modal_added_to_cart span{
    font-size: 18px;
    color: black;
}
#btn_contenue_shopping_home :hover{
    text-decoration: none;
}
#btn_contenue_shopping_home :focus{
   outline: none;
}
.storeStois{
    padding:20px 0px;
}
.loadingSmall_home{
    text-align: center;
    display: inline-block;
    width: 100%;
    padding:23px 200px;
}
@media (max-width: 1199px) { 
    .div_get_product_more #btn_get_more_product{
        padding:10px 200px;
        border:0px;
        font-size: 16px;
        margin:20px 0px;
         outline: 0px;
    }
}
@media (max-width: 991px){
    .div_get_product_more #btn_get_more_product{
        padding:10px 100px;
        border:0px;
        font-size: 14px;
        margin:20px 0px;
         outline: 0px;
    }
}
@media (max-width: 767px){
    .storeStois{
        padding:10px 0px;
    }
}
@media (max-width: 575px){
    .div_get_product_more #btn_get_more_product{
        padding:10px 80px;
        border:0px;
        font-size: 14px;
        margin:20px 0px;
         outline: 0px;
    }
    .storeStois{
        margin: 0px;
        padding:10px 0px;
    }
    .controlCategories{
        padding:10px 0px;
        margin: 0px;
    }
}