
.one_history_prof{
    background-color: white;
    box-shadow: 0px 0px 5px gray;
    border-radius: 3px ;
    margin: 10px 0px !important; 
    padding:5px !important;
   }
.hearder__order_history{
    width: 100%;
    padding: 20px 10px;
    padding-top: 30px;
}
.div_control_profile_store{
    width: 100%;
    padding: 5px 10px;
}
#h5_code_buyer{
    text-align: left;
    font-size: 18px;
}
#h6_date_buyer{
    /* color: gray; */
    box-shadow: none;
    text-align: left;
    font-size: 12px;
    padding: 2px 5px;
    border: 0px;
}


.background_profile_store{
    text-align: left;
    background-color: var(--background_ss);
    border-radius: 30px 0px 0px 30px;
}
#image_one_store_in_history{
    border: 2px solid var(--background_ss);
   width: 60px;
   border-radius: 50%;
   display: inline;
}
#h5_name_store{
    font-size: 18px;
    margin: 0px 10px;
    display: inline;
}
.one_product_instore{
    /* border-top: 1px dotted gray; */
    padding: 10px 0px;
}
#h5_code_product{
    text-align: left;
    font-size: 14px;
}
.detail_product_history{
    display: inline;
}
#image_product_in_history{
    width: 120px;
    text-align: left;
    float: left;
    margin-right: 20px;
}

#git_waiting{
    margin-top: -10px;
    height: 20px;
}
.writen_detail_product{
    text-align: left;
    padding:10px;
}
.writen_detail_product h5{
    font-size: 14px;
}
.status_waiting{
    position: absolute;
    float: right;
    font-size: 14px;
    bottom: 0px;
    right: 0px;
    background-color: white;
    padding: 2px 20px;
    box-shadow: 1px 1px 5px gray;
    border-radius: 3px 3px 0px 0px;
    color: red;
    margin:5px;
}
#git_delivery{
    width: 60px;
}
#git_already{
    width: 30px;
}
.text_ledd{
    display: inline;
}
#btn_get_done_porduct{
    width: 100%;
    border: 0px;
    padding:0px 10px;
    padding-top: 20px;
    background: none;
    border-top: 1px solid gray;
    
}
#btn_get_done_porduct h5{
    font-size: 16px;
}
.row_tow_btn{
    text-align: left;
    display: inline-block;
    width: 100%;
    border-top:1px solid gray ;
}
#btn_buy_agile{
    border: 0px;
    background: none;
    padding: 0px 0px;
    font-size: 14px;
    width: 100%;
    margin: 0px 0px;
    color: black;
}
#btn_buy_agile p{
    margin: 0px;
   padding: 0px 10px;
}

#btn_buy_agile:focus{
    outline: none;
}
.loadingSmall_order_history{
    text-align: center;
}
@media (max-width: 1199px) { 
}
@media (max-width: 991px){
    #btn_get_done_porduct h5{
        font-size: 12px;
    }
    #btn_get_done_porduct{
        width: 100%;
        border: 0px;
        padding:0px 10px;
        padding-top: 0px;
        background: none;
        border-top: 1px solid gray;
    }
    #btn_buy_agile{
        padding: 0px 0px;
        font-size: 14px;
    }
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
}