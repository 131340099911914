
#btn_Share_detail{
    border: 0px;
    padding:10px 30px;
    margin: 0px 10px;
}

.writingdetailPro{
    text-align:start;
    margin-top:50px;
}
.writingdetailPro img{
    max-width: 100%;
}
.detailClass{
    padding-top: 80px;
}

.writingdetailPro h3{
    padding-top:50px;
    padding-bottom: 10px;
    border-bottom: 1px solid gainsboro;
    font-size: 18px;
    font-family: var(--fontFamily_Kantumruy)
}
.button_col button{
    border: 0;
    margin: 1px;
}
.GetDetail h3 ,h6{
    font-family: var(--fontFamily_Kantumruy)
}
.GetDetail h6{
    font-size: 18px;
    padding:20px 0 0 0 ;
    
}
.GetDetail h5{
    padding:20px 0 0 0 ;
    font-size: 18px;
}
#nameProd{
    font-size: 22px;
    font-family: var(--fontFamily_Kantumruy)
}
#priceDicount{
    color:red;
    font-size: 20px;
    text-decoration-line: none;
}
.GetDetail span{
    font-size: 18px;
    text-decoration-line: line-through;
}
.choise_sku{
    padding:10px 0px;
}
.choise_sku span{
    color: rgb(3, 169, 245);
}
#btnLoopSKU{
    background-color: #ffeeb6fd;
    margin-right: 5px;
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:2px 10px 2px 10px;  
    width: auto; 
}
#btnLoopSKUClicked{
    background-color: rgb(3, 169, 245);
    margin-right: 5px;
    color:rgb(39, 39, 39);
    outline: 10px solid rgb(3, 169, 245);
    box-shadow:0px 0px 1px rgb(20, 20, 20);
    padding:2px 10px 2px 10px
}
.detail_block button{
    border: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
}
.btn3Support .iconthis{
    font-size: 18px;
    margin: 0px 5px 0px 0px;
}
.btn3Support button{
    color: rgb(3, 169, 245);
    margin: 0px 5px 0px 5px;
}
.btn3Support button:hover{
    color: black;
    margin: 0px 5px 0px 5px;
}
.detail_block #buttonADDTOCART{
    min-width:100%;
    margin: 0px 0px ;
    padding: 10px 20px;
    background-color: rgb(3, 169, 245);
    border-radius: 5px;
}
.detail_block #buttonADDTOCART:hover{
    min-width:100%;
    margin: 0px 0px 0px 0px ;
    padding: 10px 20px;
    background-color: #ffeeb6fd;
    border-radius: 5px;
}
.smallTitleSocail{
    width: 100%;
    background-color: #ffeeb6fd;
    text-align: center;
}
#bntSLEFT{
    position:absolute;
    bottom:50%;
    border:0;
    margin-left: 10px;
    background-color: rgba(255, 255, 255, 0);
}
#bntSRIGHT{
    background-color: rgba(255, 255, 255, 0);
    position:absolute;
    border:0;
    top:45%;
    right:20px
}
.detail_block #buttonBuyNow{
    margin: 0px 5px;
    width: 100%;
    background-color: rgb(3, 169, 245);
    border-radius: 5px;
    padding: 10px 20px;
}
.detail_block #buttonBuyNow:hover{
    width: 100%;
    background-color: #ffeeb6fd;
    border-radius: 5px;
    padding: 10px 20px;
}

.detail_block .count_detail button{
    margin: 0;
    padding: 0;
    font-size: 16px;
    width: 50px;
    height: 100%;
    background-color: whitesmoke;
    padding: 10px;
}
.detail_block .count_detail h1{
    font-weight: bolder;
    width: 50px;
    margin: 0;
    font-size: 16px;
    padding: 10px;
    background-color: whitesmoke;
}
.detail_block{
    font-family: var(--fontFamily_Kantumruy)
}

.detail_block h6{
    font-size: 16px;
    font-family: var(--fontFamily_Kantumruy);
    margin-top: 20px;
}
.detail_block h6 span{
    font-size: 14px;
    margin-top: 20px;
}

.detail_block h1 span{
    font-size: 20px;
    font-family: var(--fontFamily_Kantumruy);
    margin-top: 20px;
    margin-bottom: 20px;
}
.totalSKUPrice h2 {
    color: black;
    font-size:20px;
    font-family: var(--fontFamily_Kantumruy);
    font-weight: bolder;
    padding:10px 0px; 
}
.totalSKUPrice h1{
    padding:10px 0px;   
    margin:0px;
    font-size: 20px;
}
.totalSKUPrice h1 span{
    color: red;
    font-size:30px;
    font-family:var(--fontFamily_Kantumruy);
    font-weight: bolder;
    padding:10px 0px; 
    margin:0px;
}
.detail_block label{
    margin:0px;
    margin:3px 0px 0px 3px;
    padding:2px 5px 2px 5px;
    background-color: rgb(3, 169, 245);
    color: white;
    font-size: 12px;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    text-align: center;
}
.btnLoopDetail{
    border: 1px solid gainsboro;
    background-color: gainsboro;
    margin:0px 2px 0px 2px;
    padding: 2px 10px 2px 10px;
    font-size: 16px;
}
.btnLoopDetailClicked{
    border: 1px solid gainsboro;
    background-color: #ffeeb6fd;
    margin:0px 2px 0px 2px;
    padding: 2px 10px 2px 10px;
    font-size: 18px;
}
.btnCount{
   
    border: 1px solid black;
    background-color: gainsboro;
    width: 30px;
    height: 30px;
}
#Countinput_detail:focus, 
.btnCount:focus, 
.detail_block.count button:focus,
.btn3Support .iconthis:focus,
#bntSLEFT:focus,
#bntSRIGHT:focus
{
    outline: none;
}
#Countinput_detail{
    width: 70px;
    height: 44px;
    background-color: gainsboro;
    border: 0px;
    text-align: center;
}
#total{
    font-size: 28px;
    color: red;
    text-decoration-line: none;
}
#productInstock{
    font-size: 16px;
    color: rgb(148, 148, 148);
}
.btnTwo{
    font-size: 20px;
    background-color:#ffeeb6fd ;
    padding: 10px 50px 10px 50px;
    border: 0;
    margin: 40px 50px 0px 0px;
}


#MainImageLoopImageDetial{
    box-shadow: 1px 1px 3px gray;
    margin: 0px 0px 10px 0px;
    width: 100%; 
    height: 600px;
 }
 .col_small_img{
     padding: 1px;
     height: auto;
     margin: 0px;
 }
 .row_controlSmallImage{
     padding:0px;
     margin:0px
 }
 .image_small{
    /* box-shadow: 0px 0px 1px gray; */
    width: 100%;
    padding: 5px;
    
 }
 .image_small_Click{
    box-shadow:0px 0px 5px gray;
    width: 100%;
    padding: 5px;
}
 #btnShop1 {
    padding: 5px 20px 5px 20px;
    background-color: #ffeeb6fd;
    border: 0px;
    font-size: 18px;
    border-radius: 5px;
    margin: 0px 0px 20px 0px;
    color: black;
    width: auto;
    display: none;
    float:right;
    
 }
 #btnShop2{
    padding: 5px 20px 5px 20px;
    background-color: #ffeeb6fd;
    border: 0px;
    font-size: 18px;
    border-radius: 5px;
    margin: 0px 0px 0px 0px;
    color: black;
    width: auto;
 }

 #buttonADDTOCART:focus,
 #buttonBuyNow:focus,
 #btnLoopSKUClicked,
 #btnLoopSKU{
     outline: none;
 }
 .RatingClassn{
    padding: 10px 0px;
 }

 .show_cout_like_share h1{
    font-size: 16px;
    text-align: left;
    width: 100%;
    padding: 10px 0px;
    margin: 0px;
 }
 .show_cout_like_share .like_count{
    padding: 5px 0px;
    border-right: 1px solid gray;
    text-align: center;   
}
.show_cout_like_share .like_count h5,
.show_cout_like_share .like_share h5
{
    padding:5px 0px;
    font-size: 16px;
    color:gray
}
.show_cout_like_share .like_count #icon_like_ditail
{
    font-size: 30px;
    float: left;
    color: rgba(220, 20, 60, 0.212);
}
.show_cout_like_share .like_share i{
    float: left;
    color: rgba(220, 20, 60, 0.212);
    font-size: 20px;
    padding: 5px 20px;
}
.show_cout_like_share .like_share{
    padding: 5px 0px;
    text-align: center;  
}
.control_show_sku h6{
    font-size: 18px;
}
.control_show_sku h5{
    margin:0px 10px;
    padding:5px 10px;
    background-color: #ffeeb6fd;
    color:black;
    font-weight: 900;
    display: inline-block;
    border-bottom:1px dotted gray;
    font-size: 16px;
}
.detail_block_price{
    padding:20px 0px;
}
.detail_block_price h1{
    margin: 0px 20px;
    display: inline;
    font-size: 30px;
    color:gray;
    font-weight: bolder;
    text-decoration-line: line-through;
}
.detail_block_price h2{
    display: inline;
}
.detail_block_price h2 span{
    display: inline;
    color:red;
}
.name_show_prod{
    font-size: 25px;
    text-decoration-line: underline;
}
.many_product_selected{
    margin:20px 0px;
}
.many_product_selected .count{
    margin:0px;
    padding:0px;
    }
#imageSHOWPRODUCT_detail{
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px gray;
}


#image_product_detail{
    width: 40px;
    border-radius: 50%;
    display: inline;
    margin-right: 10px;
}
.one_sku_detail p{
    font-size:14px ;
    display: inline;
}

#Pre_slide_in_detail_prod{
    width: 50px;
    height: 30px;
    background-color: red;
    z-index: 1;
    left: 0px;
}
#Nex_slide_in_detail_prod{
    width: 50px;
     height: 30px;
     background-color: red;
     z-index: 1;
     right: 0px;
}
.controle_iamg_detail___{
    width: 100%;
}
#image_store_stories {
    width: 100%;
    padding: 10px;
    border-radius: 30px;
}
#btn_show_other_image_more{
    border: 0px;
    padding:5px 20px;
    margin-top: 20px;
    float: right;
}
.loadingSmall_detailShop{
    width: 100%;
    text-align: center;
}
.discout_phara{
    font-size: 16px;
    color: red;
    padding: 10px 0px;
}
.discout_phara .oneDis{
    font-size: 14px;
    color: gray;
}
#TotalALL{
    color: gray;
    margin: 0px 20px;
    font-size: 16px;
    display: inline;
}    
#TotalALL span{
    font-size: 16px;
    color: gray;
    text-decoration-line: line-through;
}
.show_discount_persent h1{
    display: inline-block;
    border-radius: 10px 0px 50px 0px;
    font-size: 20px;
    background-color: red;
    padding: 10px 20px;
    color: white;
}
.detail_block .count_detail{
    padding: 10px 0px;
}
.div_store_detail{

}
@media (max-width: 1199px) { 
    .show_discount_persent h1{
        font-size: 16px;
        padding: 5px 20px;
    }
    .detail_block_price{
        padding:10px 0px;
    }
    .detail_block_price h1 span{
        font-size: 14px;
    }
    .detail_block_price h2 span{
        font-size: 20px;
        font-weight: bold;
    }
    .name_show_prod{
        font-size: 18px;
    }
    .detail_block h6{
        font-size: 12px;
        margin-top: 5px;
        color: gray;
    }
    .detail_block h6 span{
        font-size: 12px;
        margin-top: 5px;
    }
    .RatingClassn{
        padding: 5px 0px;
     }
     .detail_block #RatingStarDetail{
         font-size: 20px;
     }

    .show_cout_like_share h1{
        font-size: 14px;
    }
    #btn_show_other_image_more{
        font-size: 14px;
    }
    .control_show_sku h6{
        font-size: 16px;
    }
    .control_show_sku h5{
        font-size: 14px;
    }
    .discout_phara{
        font-size: 14px;
        color: red;
    }
    .discout_phara .oneDis{
        font-size: 14px;
        color: gray;
    }
    .writingdetailPro h3{
        font-size: 18px;
    }
    .it_show_no_data h4{
        font-size: 14px;
    }
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    #image_store_stories {
        width: 100%;
        padding: 10px;
        border-radius: 30px;
    }
    .detailClass{
        padding-top: 0px;
    }  
    #buttonBuyNow,
    .detail_block #buttonADDTOCART{
        display: none;
    }
    .totalSKUPrice h1{
        font-size: 16px;
    }
    .writingdetailPro{
        margin-top:0px;
    }
    .writingdetailPro h3{
        padding-top:10px;
    }
    .show_discount_persent{
        display: none;
    }
    .detail_block{
        padding: 30px 0px 0px 0px;
    }
    .show_cout_like_share .like_count h5,
    .show_cout_like_share .like_share h5
    {
        font-size: 12px;
    }
    .show_cout_like_share .like_count #icon_like_ditail
    {
        font-size: 20px;
        float: left;
        color: rgba(220, 20, 60, 0.212);
    }
    .show_cout_like_share .like_share i{
        float: left;
        color: rgba(220, 20, 60, 0.212);
        font-size: 15px;
        padding: 5px 20px;
    }
    .detail_block_price h1{
        margin: 0px 10px;
    }
    .writingdetailPro h3{
        font-size: 14px;
        font-family: var(--fontFamily_Kantumruy)
    }
    #Countinput_detail{
        width: 100px;
        height: 44px !important;
        background-color: gainsboro;
        border: 0px;
        text-align: center;
        border-radius: 2px !important;
    }
}
@media (max-width: 575px){
    
    .detailClass{
        padding-bottom: 0px;
    }
    .rith{
        padding-bottom: 5px;
    }
}