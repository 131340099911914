
    #lblTitle_new{
        font-size: 20px;
        font-weight: bolder;
        letter-spacing: 3px;
        margin:0px 10px;
    }
    .control_label_newTitle{
        color: rgb(0, 140, 255);
        display: inline;
        text-align: center;
        font-size: 20px;
    }

    @media (max-width: 1199px) { 
  
    }
    @media (max-width: 991px){
        #lblTitle_new{
            font-size: 18px;
        }
        .control_label_newTitle{
            font-size: 18px;
        }
    }
    @media (max-width: 767px){
        #lblTitle_new{
            font-size: 16px;
        }
        .control_label_newTitle{
            font-size: 16px;
        }
    }
    @media (max-width: 575px){
    }