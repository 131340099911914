.col-md-6{
    padding:0px;
    margin:0px;
}

.row{
    padding: 0px;
    margin: 0px;
    text-align: center;
}
.group_category .row{
    padding:0px;
    margin:0px;
}
.group_category .col{
    padding:0px;
    margin:0px;
}
.image_1,
.image_2
{
    padding: 0px;
    border: 0px;
    width: 100%;
    height: 50%;
    margin:0px;
    position: relative;
    background-color: rgb(231, 231, 231);
    outline: 1px solid white;
    cursor: pointer;
    overflow: hidden;
}

.image_1 button,
.image_2 button,
.image_4 button,
.image_5 button
{
    cursor: pointer;
    border: 0px;
    position: absolute; 
    left: 0; 
    right: 0; 
    bottom: 0%;
    margin-left: auto !important; 
    margin-right: auto !important; 
    padding:25px 25px;
    height: auto;
    font-size: 18px;
    margin: auto;
}

.image_3{
    padding: 0px;
    border: 0px;
    width: 100%;
    height: 800px;
    margin:0px;
    position: relative;
    background-color: rgb(231, 231, 231);
    outline: 1px solid white;
    cursor: pointer;
    overflow: hidden;
}
.image_3 button{
   border: 0px;
   position: absolute; 
    left: 0; 
    right: 0; 
    bottom: 0%;
    margin-left: auto; 
    margin-right: auto; 
    height: auto;
    padding:25px 25px;
    font-size: 18px;
}
.image_4,
.image_5{
    cursor: pointer;
    width: 100%;
    height: 400px;
    border: 0px;
    margin: 0px 0px;
    padding: 0px;
    background-repeat:no-repeat;
    background-size:250px;
    background-position: center;
    background-color: rgb(231, 231, 231);
    outline: 1px solid white;
}
.image_6{
    cursor: pointer;
    width: 100%;
    height: 400px;
    border: 0px;
    margin: 0px 0px;
    padding: 0px;
    position: relative;
    background-repeat:no-repeat;
    background-size:250px;
    background-position: center;
    outline: 1px solid white;
    background-color: rgb(231, 231, 231);
}
.image_6 button{
    border: 0px;
    position: absolute; 
     left: 0; 
     right: 0; 
     bottom: 0%;
     margin-left: auto; 
     margin-right: auto; 
     height: auto;
     padding:25px 25px;
     font-size: 18px;
 }
 .slick-dots li button:before {
    margin:20px 0px;
    font-size: 15px;
 }
 #next{
    width: 80px;
    height: 30px;
    background-color: red;
    z-index: 1;
    right: 0px;
}
 #prev{
    width: 80px;
    height: 30px;
    background-color: red;
    z-index: 1;
    left: 0px;
 }
 .image_pro{
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 300px;
    background-repeat: no-repeat;
    transition: all 200ms;
}
/* -------------------hover */



.image_pro:hover{
    background-color: #ffeeb6fd;
    background-size: 310px;
    transition: all 300ms;
} 
.image_pro:hover button{
     transition: all 100ms;
    font-size: 20px;
    background-color: #ffeeb6fd; 
}
.categoryScreen_small{
    display: none;
}
@media (max-width: 1199px) { 
    .image_pro{
        background-position: center;
        background-size: 230px;
        background-repeat: no-repeat;
        transition: all 200ms;
    }
    .image_6{
        height: 250px;
    }
    .image_3{
        height: 500px;
    }
    .image_4,
    .image_5{
        height: 250px;
    }
    .image_1 button,
    .image_2 button,
    .image_4 button,
    .image_5 button
    {
        font-size: 14px;   
        padding:5px 30px; 
    }
    .image_3 button{
        font-size: 14px;
        padding:5px 20px; 
    }
    .image_6 button{
        font-size: 14px;
        padding:5px 20px; 
    }
    .image_pro:hover{
        background-color: #ffeeb6fd;
        background-size: 200px;
        transition: all 300ms;
    } 
    .image_pro:hover button{
         transition: all 100ms;
        font-size: 16px;
        background-color: #ffeeb6fd; 
    }
}
@media (max-width: 991px){
    .image_pro{
        background-position: center;
        background-size: 180px;
        background-repeat: no-repeat;
        transition: all 200ms;
    }
    .image_6{
        height: 200px;
    }
    .image_3{
        height: 400px;
    }
    .image_4,
    .image_5{
        height: 200px;
    }
    .image_1 button,
    .image_2 button,
    .image_4 button,
    .image_5 button
    {
        font-size: 14px;   
        padding:5px 30px; 
    }
    .image_3 button{
        font-size: 14px;
        padding:5px 20px; 
    }
    .image_6 button{
        font-size: 14px;
        padding:5px 20px; 
    }
    #prev,#next{
        width: 50px;
    }
    
}
@media (max-width: 767px){
    .image_pro{
        background-position: center;
        background-size: 145px;
        background-repeat: no-repeat;
        transition: all 200ms;
    }
    .image_6{
        height: 180px;
    }
    .image_3{
        height: 360px;
    }
    .image_4,
    .image_5{
        height: 180px;
    }
    .image_1 button,
    .image_2 button,
    .image_4 button,
    .image_5 button
    {
        font-size: 12px;   
        padding:5px 10px; 
    }
    .image_3 button{
        font-size: 12px;
        padding:5px 10px; 
    }
    .image_6 button{
        font-size: 12px;
        padding:5px 10px; 
    }
    #prev,#next{
        width: 40px;
    }
    .image_pro:hover{
        background-color: #ffeeb6fd;
        background-size: 180px;
        transition: all 300ms;
    } 
    .image_pro:hover button{
         transition: all 100ms;
        font-size: 16px;
        background-color: #ffeeb6fd; 
    }
}
@media (max-width: 575px){
    .categoryScreen_big{
        display: none;
    }
    .categoryScreen_small{
       display: block;
    }
    .iamge_smail_cat{
        padding:5px 15px;
        text-align: center;
    }
    .iamge_smail_cat img{
        width: 100%;
    }
    .iamge_smail_cat button{
        font-size: 10px;
        border: 0px;
        padding: 0px;
        background: none;
    }
}
