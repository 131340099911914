.topSeachInput{
    margin:10px 0px;
    display: none;
    background-color: antiquewhite;
    /* position: fixed; */
}
#searchProd_topSearch{
    padding: 10px 0px;
    font-size: 18px;
}
#btn_search_Prod{
    margin: 10px 0px;
    width: 100%;
    border: 0px;

}
.one_col{
    margin: 0px;
    padding: 0px;
    min-height: 300px;
    position: relative;
}
.oneSearch{
   width: 100%;
    position: absolute;
    top: 0;
}
.resultSearch {
    margin:20px 0px;
}
.resultSearch h1{
    padding:0px 10px;
    font-size: 30px;
    text-decoration: underline;
}
.loadingSmall_search{
    padding:30% 0px; 
    width: 100%;
}
.nodataMore_search {
    padding:30% 0px; 
    width: 100%;
}
.nodataMore_search h1{
    font-size: 20px;
    text-decoration: none;
}
@media (max-width: 1199px) { 
  
}
@media (max-width: 991px){
    .resultSearch {
        margin:10px 0px;
    }
    .resultSearch h1{
        font-size: 16px;
    }
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    .resultSearch {
        margin:10px 0px;
    }
    .resultSearch h1{
        font-size: 16px;
    }
}