.modelDeleteCart{
  padding:10px; 
}
.newPincode{
  background-color: rgba(128, 128, 128, 0.787);
}
#showPassword_newPin{
  
  padding:10px;
  border: 0px;
  background: none;
  height: 20px;
  color: #4f5b6698;
}
#icon_Visibility{
  margin-right: 10px;
}
.falseVerify{
  background-color: rgba(19, 19, 19, 0.787);
}

#showPassword:focus{
  outline: none;
}
#Yes_newPin{
  background-color: #ffeeb6fd;
  padding:10px 0px;
  width: 100%;
  color: black;
  border:none;
  border-radius: 5px;
}
#Yes_newPinDisable{
  padding:10px 0px;
  width: 100%;
  border:none;
  border-radius: 5px;
}
#Yes_newPin,:focus{
  outline: none;
}






.code-input {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .code-label {
    margin-bottom: 16px;
  }
  .code-inputs {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  .code-inputs input {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    text-align: center;
    height: 60px;
    width: 40px;
    border-radius: 10px;
    margin: 0 4px;
    border: 2px solid #4f5b66;
    font-size: 38px;
  }
  .code-inputs input:focus {
    outline: none;
  }
  .code-inputs input:nth-child(3n) {
    margin-right: 24px;
  }

  @media (max-width: 575px){
    .modelDeleteCart input{
        width: 40px !important;
        margin: 0px 1px !important;
        height: 40px !important;
        font-size: 16px !important;
        border-radius: 15px !important;
    }
    .modelDeleteCart .pincode-input-container{
      margin: 10px 0px;
      padding: 0px !important;
    }
  }
  @media (max-width: 352px){
      .modelDeleteCart input{
          width: 35px !important;
          height: 35px !important;
      }
  }