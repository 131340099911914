.Container_custome{
    max-width: 100%;
    padding:0px 8%;
    padding-top: 0px;
    height: auto;
    margin:0px;
}
.profile_image{
    height: 400px;
    position: relative;
    padding:10px;
    margin:100px 0px;
    border-radius: 5px;
    box-shadow: 1px 1px 10px gray;
}
.profile_image #btn_change_color{
    right: 10px ;
    top:10px;
    position: absolute;
}
.image_user{
    position: relative;
    width: 60%;
}
.detail_setting{
    box-shadow: 0px 0px 5px gray;
    padding:20px 50px 10px 10px; 
    background-color: white;
    position: absolute;
    top: -50px;
    width: 40%;
    right: 0;
    border-radius: 5px;
}
.detail_setting  ul{
    list-style-type: none;
    height: 100%;
}
.detail_setting  ul li{
    border-radius: 10px;
    margin: 5px 0px;
    box-shadow: 0px 2px 1px 0px gray;
    padding: 20px 10px;
    font-size: 20px;
    transition: all 3s;
}
#icon_VpnKey,
#icon_edit_location,
#icon_history,
#icon_about,
#icon_tasks,
#personal_role{
    margin: 0px 10px;
    font-size: 20px;
}
.detail_setting  ul li:hover{
    margin: 5px 0px 5px -30px;
    transition: all 1s;
    background-color: #FFEEB6FD;
}
.image_user #image_profile_user{
    background-color: white;
    border:1px solid gray;
    border-radius: 20px;
    width: 180px;
    height: 180px;
    display: inline;
}

.name_user .name___{
    padding: 30px 0px 0px 20px;
    display: inline-block;
}
.name_user .name___ h1,
.name_user .name___ h2{
    font-size: 25px;
}
#btn_edit_image{
    border: 0px;
    margin-left: -30px;
    position: absolute;
    bottom: 0;
    background: none;
}
#btn_edit_image i{
   font-size: 40px;
   color: darkcyan;
}
.three_button{
    border: 0px;
    margin:20px 0px;
    width: 60%;
    bottom:60px;
    position: absolute;
}
.three_button button{
    border: 0px;
    margin:0px 8px;
    padding:20px 10px;
    width: 30%;
    font-size: 20px;
    border-radius: 5px;
}
.pro_address{
    margin: 5px 0px;
    padding: 10px 0px 10px 20px;
    width: 60%;
}
.pro_address h1{
    font-size: 20px;
    word-wrap: break-word;
}
.socail{
    width: 60%;
    bottom: 10px;
    position: absolute;
}
.socail button{
    background: none;
    margin: 0px 10px;
    width: 45%;
    border: 0px;
}
.socail button i{
    margin: 0px 5px;
}
.show_data_user_done{
    background-color: white;
    padding:30px 0px;
}
.show_data_user_done h6{
    background-color: antiquewhite;
    padding:10px 20px;
    box-shadow: 0px 2px 2px gray;
    border-bottom: 2px double gray ;
    font-size: 20px;
}
.one_day_of_transaction{
    padding: 0px;
}
.one_show_day_of_transaction{
    box-shadow: 0px 0px 1px gray;
    margin: 0px;
    
}
.hearder_one_day_of_transaction{
    border-radius: 0px;
    background-color: rgb(214, 214, 214);
    font-size: 18px;
    text-align: left;
    line-height: 18px;
    padding: 10px 5px 1px 20px ;
}
.one_action_tran{
    border-bottom: 1px solid rgb(221, 221, 221);
}
.one_action_tran:hover{
    cursor: pointer;
}
.type_of_action{
    padding: 10px 20px 0px 20px;
    display: inline-block;
    text-align: left;
    width: 70%;
}
.color_blue{
    color: blue;
    padding: 10px 10px 10px 20px;
    display: inline-block;
    text-align: right;
    width: 30%;
}
.color_red{
    color: red;
    padding: 10px 10px 10px 20px;
    display: inline-block;
    text-align: right;
    width: 30%;
}
.color_red p{
    text-align: right;
}
#btn_transaction_dataMore{
    float: right;
    padding: 5px 40px;
    border: 0px;
    margin: 0px 0px;
    font-size: 14px;
}
.this_button_active{
    background-color: blanchedalmond;
}

#title_reHistory{
    font-size: 20px;
}
#mainTitle_trancsantion{
    font-size: 18px;
    font-family: var(--fontFamily_Ork);
    font-weight: bold;
}
.dailog_show_transaction{
    text-align: left;
}
.dailog_show_transaction p{
    line-height: 25px;
    font-size: 14px;
    font-weight: normal;
    font-family: var(--fontFamily_Kantumruy);
}
.dailog_show_transaction span{
    margin-left: 10px;
    font-size: 18px;
    font-family: var(--fontFamily_Kantumruy);
}

.dailog_show_transaction_date{
    text-align: right;
    color:gray;
    font-size: 12px;
    font-family:var(--fontFamily_Kantumruy);
}
.wallet_User_div{
    font-family:var(--fontFamily_Kantumruy);
    
}
.wallet_User_div h1{
    font-size: 18px;
}
.wallet_User_div h2{
    color: red;
    font-size: 30px;
}
.wallet_User_div span{
    font-size: 25px;
    padding: 0px 10px;
}
#btn_caseIN_video{
    font-size: 14px;
    width: 100%;
    border: 0px;
    padding: 10px 0px;
}
.profile_show_data_screen_small,.setting_small_screen{
    display: none;
}
.show_data_user_done h1{
    font-size: 16px;
}
.Viviviv{
    display: block;
}
@media (max-width: 1199px) { 
    .three_button button{
        font-size: 16px;
    }
    .name_user .name___{
        padding: 30px 0px 0px 20px;
        display: inline-block;
    }
    .name_user .name___ h1,
    .name_user .name___ h2{
        font-size: 20px;
    }
    .detail_setting{
        box-shadow: 0px 0px 5px gray;
        padding:20px 50px 10px 10px; 
        background-color: white;
        position: absolute;
        top: -50px;
        width: 40%;
        right: 0;
        border-radius: 5px;
    }
    .detail_setting  ul{
        list-style-type: none;
        height: 100%;
    }
    .detail_setting  ul li{
        border-radius: 10px;
        margin: 5px 0px;
        box-shadow: 0px 2px 1px 0px gray;
        padding: 20px 10px;
        font-size: 16px;
        transition: all 3s;
    }
}
@media (max-width: 991px){
    .image_user #image_profile_user{
        width: 150px;
        height: 150px;
    }
    .three_button{
        border: 0px;
        margin:20px 0px;
        width: 60%;
        bottom:60px;
        position: absolute;
    }
    .three_button button{
        border: 0px;
        margin:0px 5px;
        padding:20px 5px;
        width: 28%;
        font-size: 16px;
        border-radius: 5px;
    }
    .profile_image{
        height: 350px;
    }
    .detail_setting{
        box-shadow: 0px 0px 5px gray;
        padding:20px 10px 10px 0px; 
        background-color: white;
        position: absolute;
        top: -50px;
        width: 40%;
        right: 0;
        border-radius: 5px;
    }
    .detail_setting  ul{
        list-style-type: none;
        height: 100%;
        padding: 0px 20px;
    }
    .detail_setting  ul li{
        border-radius: 10px;
        margin: 5px 0px;
        box-shadow: 0px 2px 1px 0px gray;
        padding: 15px 10px;
        font-size: 16px;
        transition: all 3s;
    }
}
@media (max-width: 767px){
    .image_user #image_profile_user{
        width: 100px;
        height: 100px;
    }
    .three_button{
        border: 0px;
        margin:20px 0px;
        width: 60%;
        top:150px;
        position: absolute;
    }
    .three_button button{
        border: 0px;
        margin:0px 5px;
        padding:10px 5px;
        width: 28%;
        font-size: 14px;
        border-radius: 5px;
    }
    .profile_image{
        height: 250px;
    }
    .detail_setting{
        box-shadow: 0px 0px 5px gray;
        padding:10px 10px 10px 0px; 
        background-color: white;
        position: absolute;
        top: -50px;
        width: 40%;
        right: 0;
        border-radius: 5px;
    }
    .detail_setting  ul{
        list-style-type: none;
        height: 100%;
        padding: 0px 10px;
    }
    .detail_setting  ul li{
        border-radius: 10px;
        margin: 5px 0px;
        box-shadow: 0px 2px 1px 0px gray;
        padding: 10px 10px;
        font-size: 13px;
        transition: all 3s;
    }
    .socail button{
        background: none;
        margin: 0px 0px;
        width: 45%;
        border: 0px;
        font-size: 14px;
    }
    .socail button i{
        margin: 0px 0px;
    }
    #icon_VpnKey,
    #icon_edit_location,
    #icon_history,
    #icon_about,
    #icon_tasks,
    #personal_role{
        margin: 0px 10px;
        font-size: 18px;
    }
}
@media (max-width: 575px){
    .detail_setting{
        display: none;
    }
    .profile_image{
        height: 150px;
        margin:10px 0px;
    }
    .image_user{
        width: 100%;
    }
    .image_user #image_profile_user{
        width: 80px;
        height: 80px;
        }
        .name_user .name___{
            padding: 10px 0px 0px 20px;
            display: inline-block;
        }
        .name_user .name___ h1,
        .name_user .name___ h2{
            font-size: 14px;
        }
        .socail{
            width: 100%;}
        .socail button{
            font-size: 13px;
            background: none;
            margin: 0px 0px;
            width: 50%;
            border: 0px;
        }
        .socail button i{
            font-size: 14px;
            margin: 0px 5px;
        }
        .three_button{
            display: none;
        }
        .profile_show_data_screen_small{
            display: inline;
        }
        .profile_show_data_screen_small button{
            border: 0px;
            margin:0px 0px;
            padding:10px 5px;
            width: 33.33%;
            font-size: 14px;
            border-radius: 0px;
            margin-top: 30px;
        }
        .show_data_user_done{
            background-color: white;
            padding:10px 0px;
        }
        .show_data_user_done h6{
            font-size: 16px;
        }
        .setting_small_screen{
            display: flex;
        }
        .setting_small_screen button{
            font-size: 10px;
            border: 0px;
            margin: 3px 0px;
            padding: 5px 0px;
            width: 100%;
            height: 100%;
        }
        .setting_small_screen button i{
           display: block;
        }
        #icon_VpnKey,
        #icon_edit_location,
        #icon_history,
        #icon_about,
        #icon_tasks,
        #personal_role{
            margin: 3px 10px;
            font-size: 25px;
        }

        .wallet_User_div{
            font-family:var(--fontFamily_Kantumruy);
            
        }
        .wallet_User_div h1{
            font-size: 18px;
        }
        .wallet_User_div h2{
            color: red;
            font-family:var(--fontFamily_Kantumruy);
            /* font-size: 25px; */
        }
        .wallet_User_div span{
            font-size: 25px;
            padding: 0px 10px;
        }
        .type_of_action{
            padding: 10px 0px 0px 0px;
            font-size: 14px;
        }
        .color_red{
            font-size: 14px;
            padding: 5px 10px 10px 10px;
            
        }
        .hearder_one_day_of_transaction{
            font-size: 16px;
            padding: 5px 2px;
        }
        .hearder_one_day_of_transaction p{
            margin: 0px;
        }
        #mainTitle_trancsantion{
            font-size: 16px;
        }
        .detail_train_one_one{
            font-size: 16px;
        }
}