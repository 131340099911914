.oneGroup{
    box-shadow: 0px 0px 5px gray;
    margin: 10px 0px 0px 0px;
    padding:10px
}
.lblNoData{
    position: absolute;
    top:50%;
    left:50%;
    font-size:16px 
}
.oneCart{
     background-color: rgb(223, 223, 223);
     padding:5px;
     margin: 5px;
}
.cart .Container{
    padding: 30px;
    margin-bottom: 200px;
}

.checkboxProd{
    z-index: 1;
    position: absolute;
    left: -1px;
    top: -1px;
    width: 20px;
    height: 20px;
    /* margin: 0px 50px 20px 20px; */
}
.checkboxProd:focus{
    outline: none;
}   
.checkboxProd:hover{
    background-color: black;
}
.detailProd{
    text-align: left;
}  
.detailProd h1{
    margin: 0px;
    padding: 3px 0px;
    font-size: 18px;
    word-wrap: break-word;
} 
.detailProd h2{
    margin: 0px;
    padding: 3px 0px;
    word-wrap: break-word;
    font-size: 18px;
} 
.detailProd h3{
    margin: 0px;
    padding: 3px 0px;
    word-wrap: break-word;
    font-size: 18px;
} 
.count{
    display: block;
    margin: auto;
    padding-left:20px ;
    
}
#oneProdInStore{
    border:1px solid rgb(58, 58, 58);
    padding: 10px;
    margin-bottom: 10px;
    position: relative;
}
.totalPriceProduct{
    display: block;
    margin: auto;
}
.totalPriceProduct h2{
    font-size: 22px;
    color: brown ;
}

.count #Countinput{
    width: 70px;
    height: auto;
    background-color: gainsboro;
    border: 0px;
    text-align: center;
    font-size: 16px;
    padding: 10px;
}
 .count button{
    border: 0px;
    height: auto !important;
    font-size: 16px;
    width: 50px;
    height: 100%;
}
.count .button_dis_count{
    width: 50px;
    height: auto;
}

.count button:focus{
    outline: none;
}


.totalPriceProduct h1{
    font-size: 16px;
}

#btnRemove{
    background:none;
    padding: 5px;
    border: 0px;
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
}
#btnRemove #RemoveCircleOutline{
    margin-top: 0px;
    margin-right: 0px;
    font-size: 25px;
}

#btnRemove:focus{
    outline: none;
}

.checkAll{
    padding: 10px;
    font-size: 20px;
    float: left;
    border-bottom: 2px solid black;
}
.checkAll input{ 
   width: 20px;
   height: 20px;
  
}
.detailTotal{
    margin: 10px;
}
.detailTotal button{
    float: left;
    border-top: 1px solid black;
    margin: 10px;
}
.imagePro{
    width: 100%;
}

.showTotal {
    padding: 20px;
    padding-top:10px ;
    float: right;
}
.showTotal h1{
   font-size: 25px;
   float: left;
}
.showTotal h2{
    font-size: 20px;
    float: left;
 }
.thistotal{
    color: brown ;;
    font-size: 30px;
    padding: 10px;
 }
 .control_topay{
     margin: 50px auto auto auto;
 }
 #toPay{
     border-radius: 5px;
     width: 100%;
     border: 0px;
     padding: 10px;
     font-size: 25px;
 }
 .navBuyTotal{
    right: 0px;
    position: fixed;
    bottom:0%;
    width: 100%;
    z-index: 2;
   
 }
 .navBuyTotal_1{
     box-shadow: 0px -2px 2px rgb(190, 190, 190);
     background-color: rgb(241, 241, 241);
     padding: 0px 20px  ;
     padding-top: 10px;
 }
 .modelDeleteCart h1{
    font-size: 18px;
 }
 .modelDeleteCart #btnHide{
    font-size: 18px;
 }
 
 .modelDeleteCart span{
    font-size: 18px;
    color: red;
 }


 .detailProdImage{
     margin: 10px;
     padding: 10px;
     background-color:  rgb(243, 243, 243);
     border-radius: 5px;
     box-shadow: 1px 1px 5px gray;
 }
 .oneCart a{
     text-decoration: none;
     color: black;
 }
 .headerShop #logoStore{
    width: auto;
    height: 80px;
    border-radius: 10px;
    margin-left: 10px;
    box-shadow: 1px 1px 2px gray;
}
.headerShop h1{
    display: block;
    margin: auto auto auto 10px;
    font-size: 20px;
}
.headerShop:hover {
    cursor: pointer;
}
.oneCart_disable{
    color: gray;
    position: relative;
}
.oneCart_disable .checkboxProd{
    opacity: 0;
}
.oneCart_disable .lable_cart_no_store{
    position: absolute;
    top: -5px;
    border-radius: 5px;
    left: -5px;
    padding: 5px 10px;
    background-color: red;
    color: beige;
}
.emtyCart___{
    position: relative;
    width: 100%;
    text-align: center;
}
.emtyCart___ img{
    width: 360px;
}
.emtyCart___ h1{
  font-size: 25px;
}
.emtyCart___ p{
    font-size: 20px;
    color: grey;
  }
  #closeBuyAlread{
      border: 0px;
      width: 100%;
      padding: 15px 0px;
      background: none;
      margin: 0px;
  }
  .modal_buyAready{
      background-color: rgba(240, 248, 255, 0.671);
  }
  .modal_buyAready h1{
   font-size: 20px;
    }
  .image_prod_add_to_cart{
      position: relative;
  }
  .image_prod_add_to_cart p{
      position: absolute;
      right: 0px;
      background-color: red;
      color: white;
      border-radius: 0px 0px 0px 50px;
      padding:2px 5px;
      font-size: 14px;
  }
  .total_amout_and_dis_amount_persent{
      position: relative;
  }
  .total_amout_and_dis_amount_persent p{
    font-size: 14px;
    margin-bottom:-20px ;
    text-decoration: line-through;
  }
  .total_amout_and_dis_amount_persent p span{
    font-size: 10px;
    color: white;
    position: absolute;
    top: -20px;
    left: -50px;
    padding: 2px 5px;
    border-radius: 0px 0px 10px 0px;
    background-color: red;
  }
    .oneCartSHOW_Small{
    display: none;
    }
    .div_show_cart_ss{
        padding:20px 0px 200px 0px
    }
@media (max-width: 1199px) { 
    #toPay{
        padding: 5px;
        font-size: 20px;
    }
   .showTotal h1{
    font-size: 20px;
    float: left;
    }
    .thistotal{
        color: brown ;;
        font-size: 20px;
        padding: 10px;
     }
    .checkAll{
        padding: 10px;
        font-size: 20px;
        float: left;
        border-bottom: 2px solid black;
    }
    .checkAll input{ 
    width: 20px;
    height: 20px;
    }
    .checkAll span{
        font-size: 18px;
    }
    .oneCart_disable .lable_cart_no_store{
        font-size: 10px;
    }
    .totalPriceProduct{
        display: block;
        margin: auto;
    }
    .totalPriceProduct h2{
        font-size: 18px;
    }
    .detailProd h1{
        font-size: 16px;
    } 
    .detailProd h2{
        font-size: 16px;
    } 
    .detailProd h3{
        font-size: 16px;
    } 
    .totalPriceProduct h1{
        font-size: 14px;
    }
    .total_amout_and_dis_amount_persent p span{
        padding: 2px 2px;
      }
    .emtyCart___ img{
        width: 320px;
    }
    .emtyCart___ h1{
      font-size: 20px;
    }
    .emtyCart___ p{
        font-size: 18px;
      }
}
@media (max-width: 991px){
    .div_caculate{
        display: flex;
        flex-direction: column-reverse;
    }
    .showTotal{
        font-size: 18px;
        padding: 0px;
        padding-top: 20px;
    }
    #toPay{
        background-color:rgb(3, 169, 245) ;
        border-radius: 5px;
        width: 100%;
        border: 0px;
        padding: 10px;
        font-size: 20px;
    }
    .count{
        padding: 20px !important;
        margin: 0px !important ;
    }

    .count #Countinput{
        width: 50px;
        font-size: 14px;
    }
    .count button{
        width: 35px;
        
    }
    .count .button_dis_count{
        width: 35px;
    }

    .count button:focus{
        outline: none;
    }
    .emtyCart___ img{
        width: 280px;
    }
    .emtyCart___ h1{
      font-size: 18px;
    }
    .emtyCart___ p{
        font-size: 16px;
      }
}
@media (max-width: 767px){
    .showTotal{
        font-size: 18px;
        padding: 0px;
        padding-top: 20px;
        padding-right:20px ;
    }
    .headerShop #logoStore{
        width: auto;
        height: 50px;
        border-radius: 5px;
    }
    .headerShop h1{
        font-size: 16px;
    }
    #oneProdInStore{
        display: none;
    }
    .oneCartSHOW_Small{
        border:1px solid rgb(58, 58, 58);
        position: relative;
        margin: 10px 0px !important;
        padding: 5px !important;
        display: flex;
    }
    .oneCartSHOW_Small .totalPriceProduct .total_amout_and_dis_amount_persent h1{
        float: left;
    }
    .oneCart_disable .lable_cart_no_store{
        position: absolute;
        top: 2px;
        border-radius: 5px;
        left: 2px;
        padding: 3px 8px;
        background-color: red;
        color: beige;
    }
    .total_amout_and_dis_amount_persent span{
        display: none;
    }
    .total_amout_and_dis_amount_persent p{
        margin: 0px;
        /* display: none; */
    }
    #dis_small_show{
        font-size: 14px;
        color: gray;
        text-decoration: line-through;
    }
    .totalPriceProduct{
        padding: 20px 10px;
        margin: auto;
    }
    .imagePro{
        border-radius: 5px;
    }
    .emtyCart___ img{
        width: 280px;
    }
    .emtyCart___ h1{
      font-size: 16px;
    }
    .emtyCart___ p{
        font-size: 15px;
      }
}
@media (max-width: 575px){
    .detailProd h1{
        font-size: 14px;
    } 
    .detailProd h2{
        font-size: 14px;
    } 
    .detailProd h3{
        font-size: 14px;
    } 
    #img_logo_receipt{
        display: none;
    }
    .hearderReceipt h3{
        text-align: left;
    }
    .showTotal {
        padding: 10px;
        padding-top:10px ;
        padding-right: 20px;
        float: inline-end;
        display: block;
    }
  
    .showTotal h1{
        font-size: 16px;
        float: left;
     }
     .showTotal h2{
         font-size: 18px;
         float: left;
      }
     .thistotal{
         color: brown ;;
         font-size: 16px;
         padding: 10px;
      }
    .checkAll{
        padding: 10px;
        font-size: 18px;
        float: none;
        border-bottom: 2px solid black;
    }
    #toPay{
        padding: 10px;
        font-size: 16px;
        margin-bottom: 10px;
    }
    .headerShop #logoStore{
        height: 40px;
        width: auto;
    }
    .oneCart{
        padding:5px;
        margin: 2px;
   }
   .detailProdImage{
        margin: 5px;
        padding: 5px;
    }
    .div_show_cart_ss{
        margin: 0px !important;
        padding:20px 0px 100px 0px
    }
    .totalPriceProduct{
        padding: 0px 0px;
        margin: 20px 0px;
    }
    .totalPriceProduct h2{
        font-size: 14px;
        margin: 0px;
        padding: 0px;
    }
    .count{
        padding:10px 0px !important;
        margin: 0px !important ;
    }
    .checkAll input {
        padding:0px !important;
        width: 20px;
        height:20px;

    }
    .checkAll span{
        font-size: 14px;
        margin-left: 10px;
    }
    .count #Countinput{
        width: 40px;
        font-size: 12px;
    }
    .count button{
        width: 30px;
    }
    .count .button_dis_count{
        width: 30px;
    }
    .showTotal {
        padding: 10px;
        padding-right:20px ;
        float: right;
    }
    .showTotal h1{
        font-size: 14px;
     }
    .navBuyTotal_1{
        padding: 0px 10px  ;
        padding-top: 0px;
    }
    .div_show_cart_ss{
       padding-bottom: 150px;
    }
    .checkboxProd{
        width: 15px;
        height: 15px;
    }
}
@media (max-width: 363px){
    .count #Countinput{
        width: 30px;
        font-size: 10px;
    }
    .count button{
        width: 25px;
    }
    .count .button_dis_count{
        width: 25px;
    }
    #btnRemove #RemoveCircleOutline{
        font-size: 20px;
    }
    #toPay{
        margin-bottom: 10px;
        padding: 10px;
        font-size: 14px;
       }
}