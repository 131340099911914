
.col_controler_oneProd{
    overflow: hidden;
    height: auto;
    width: 100%;
    transition: all 700ms;
    padding: 5px 10px;
    position: relative;
    cursor: pointer;
    outline: 3px solid rgb(255, 255, 255);
    margin: 20px 0px;
    border-radius: 5px 5px 0px 0px;
   
}
.layer_2{
    border-radius: 5px;
    width: 100%;
    /* background-color: red;
    -webkit-box-shadow: 0px 0px 20px -6px rgba(171,171,171,1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(171,171,171,1);
    box-shadow: 0px 0px 20px -6px rgba(171,171,171,1); */
}
.layer_2 #imageProd{
    border-radius: 5px 5px 0px 0px;
    width: 100%;
    height: auto;
}

.menu_sku_prod{
    width: 100%;
/*     
    -webkit-box-shadow: 0px 0px 20px -6px rgba(171,171,171,1);
    -moz-box-shadow: 0px 0px 20px -6px rgba(171,171,171,1);
    box-shadow: 0px 0px 20px -6px rgba(171,171,171,1); */
}
.like_prod{
    
    overflow: hidden;
    width: 100%;
}
.show_btn_add_to_cart{
    
    padding: 10px 5px;
    width: 100%;
    background-color: white;
    margin-left: auto; 
    margin-right: auto; 
    transition: all 1s;
   
}
.show_btn_add_to_cart h5{
    line-height:1.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; 
    -webkit-box-orient: vertical;
}
.show_btn_add_to_cart h4{
    color: red;
}

.col_controler_oneProd:hover .show_btn_add_to_cart

{
    margin-top: -50px;
}
.col_controler_oneProd:hover .show_btn_add_to_cart a
{
   color: black;
   text-decoration: none;
}
 
.like_prod #btn_like,
.like_prod #btn_share{
    padding: 20px 0px;
    outline: 2px  solid white;
    border:0px;
    border-top: 1px solid white;
    width: 50%;

}
.like_prod #btn_liked{
    color: rgb(116, 116, 116);
    padding: 20px 0px;
    outline: 1px solid white;
    background-color: rgb(214, 214, 214);
    border:0px;
    width: 50%;
}
.like_prod #btn_liked:hover{
   cursor: no-drop;
}
.like_prod #btn_like:hover{
    background-color: #ffe89bfd;
}

.like_prod #btn_share:hover{
    cursor: default;
}
.col_controler_oneProd:hover #btnCart{
    opacity: 1;
}

#btnCart{
    margin: auto;
    padding: 15px 40px;
    display: block;
    border: 0px;
    background-color: #ffe89bfd;
    opacity: 1;
    transition: all 1s;
    opacity: 0;
    background-color: #ffe89bfd;
}

.smaller_like_share{
    display: inline-block;
}
.smaller_like_share h6{
    float: left;
    width:100%;
    background-color: rebeccapurple;
}
#btnCart:focus{
    outline: 0px;
}
.like_prod #icon_like{
    padding:0px;
    margin: -5px 0px 0px 10px;
    font-size: 20px;
}
.like_prod #icon_share{
    color: goldenrod;
    margin: 0px 5px 0px 10px;
}
.have_discount_product{
    background-color:red;
    position: absolute;
    left:0px;
    top:0px;
    border-radius:5px 0px 50px 0px ;
    padding:5px 10px;
}
.have_discount_product h1{
    font-size: 16px;
    color: white;
}

.show_discount_one_product h3{
    color:red;
}
.show_discount_one_product h4{
    font-size: 15px;
    color:gray;
    text-decoration-line: line-through;
}

@media (max-width: 1199px) { 
    .col_controler_oneProd{
        padding: 5px 5px;
        margin: 10px 0px;
    }
    .show_btn_add_to_cart{
        padding: 5px 5px;
    }
    .show_btn_add_to_cart h5{
        line-height:1.5em;
        font-size: 18px;
    }
    .col_controler_oneProd:hover .show_btn_add_to_cart
    {
        margin-top: -45px;
    }
    .like_prod #btn_like,
    .like_prod #btn_share{
        padding: 10px 0px;
    }
    .like_prod #btn_liked{
        padding: 10px 0px;
       }
    
    #btnCart{
        margin: auto;
        padding: 10px 40px;
        display: block;
        border: 0px;
        background-color: #ffe89bfd;
        opacity: 1;
        transition: all 1s;
        background-color: #ffe89bfd;
    }
  
    .smaller_like_share{
        display: inline-block;
    }
    .smaller_like_share h6{
        float: left;
        width:100%;
        background-color: rebeccapurple;
    }
    #btnCart:focus{
        outline: 0px;
    }
    .like_prod #icon_like{
        padding:0px;
        margin: -5px 0px 0px 10px;
        font-size: 20px;
    }
    .like_prod #icon_share{
        margin: 0px 5px 0px 10px;
    }
    .have_discount_product{
        background-color:red;
        position: absolute;
        left:0px;
        top:0px;
        border-radius:5px 0px 50px 0px ;
        padding:5px 10px;
    }
    .have_discount_product h1{
        font-size: 16px;
        color: white;
    }
    
    .show_discount_one_product h3{
        color:red;
    }
    .show_discount_one_product h4{
        font-size: 15px;
        color:gray;
        text-decoration-line: line-through;
    }
 
}
@media (max-width: 991px){
    .col_controler_oneProd{
        padding: 5px 5px;
        margin: 10px 0px;
    }
    .show_btn_add_to_cart{
        padding: 5px 5px;
    }
    .show_btn_add_to_cart h5{
        line-height:1.5em;
        font-size: 16px;
    }
    .show_btn_add_to_cart h3{
        font-size: 20px;
    }
    .col_controler_oneProd:hover .show_btn_add_to_cart
    {
        margin-top:0px;
    }
    .like_prod #btn_like,
    .like_prod #btn_share{
        padding: 10px 0px;
    }
    .like_prod #btn_liked{
        padding: 10px 0px;
       }
    #RatingStar{
        font-size: 20px;
    }
    #btnCart{
        padding: 5px 10px;
        font-size: 14px;
    }
    .like_prod #icon_like{
        padding:0px;
        margin: -5px 0px 0px 10px;
        font-size: 20px;
    }
    .like_prod #icon_share{
        margin: 0px 5px 0px 10px;
    }
    .have_discount_product{
        background-color:red;
        position: absolute;
        left:0px;
        top:0px;
        border-radius:5px 0px 50px 0px ;
        padding:5px 10px;
    }
    .have_discount_product h1{
        font-size: 16px;
        color: white;
    }
    
    .show_discount_one_product h3{
        color:red;
    }
    .show_discount_one_product h4{
        font-size: 15px;
        color:gray;
        text-decoration-line: line-through;
    }
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    .col_controler_oneProd{
        padding: 5px 1px;
        margin: 10px 0px;
    }
    .show_btn_add_to_cart h5{
        line-height:1.5em;
        font-size: 14px;
        margin: 0px;
    }
    .show_btn_add_to_cart h3{
        font-size: 18px;
        margin: 0px;
    }
    .show_btn_add_to_cart h4{
        font-size: 14px;
    }
    .like_prod #btn_liked{
        background-color: gray;
        padding: 5px 0px;
        border-radius: 0px 0px 0px 5px;
        color: wheat;
    }
    .like_prod #btn_share{
        padding: 5px 0px;
        border-radius: 0px 0px 5px 0px;
    }
    .like_prod #btn_like{
        padding: 5px 0px;
    }
    #RatingStar{
        font-size: 16px;
    }
    #btnCart{
        margin-bottom: 10px;
        padding: 10px 10px;
        font-size: 14px;
        width: 90%;
    }
    #btnCart .iconbtn3{
        margin-top: -9px;
        font-size: 20px;
    }
    .like_prod #icon_like{
        padding:0px;
        margin: -5px 0px 0px 10px;
        font-size: 20px;
    }
    .like_prod #icon_share{
        margin: 0px 5px 0px 10px;
    }
    .have_discount_product{
        background-color:red;
        position: absolute;
        left:0px;
        top:0px;
        border-radius:5px 0px 50px 0px ;
        padding:5px 10px;
    }
    
    .show_discount_one_product h3{
        font-size: 18px;
        color:red;
    }
    .show_discount_one_product h5{
        font-size: 14px;
    }
    .show_discount_one_product h4{
        font-size: 14px;
        color:gray;
        text-decoration-line: line-through;
    }

    .like_prod #btn_like:hover,
    .like_prod #btn_share:hover{
       background-color: rgb(219, 219, 219);
    }
    .loadingSmall_home{
        padding: 0px !important;
    }
}