.recommandRow label{
    margin-top: 50px;
    font-size: 18px;
    margin-left: 10px;
    color: gray;
}
.recommandRow button{
    border:0;
    background-color: aliceblue;
}
.recommandRow #iconLeft{
    margin: 10px;
    font-size: 25px;
    transition: all 450ms;
}

.recommandRow #iconLeft:hover{
    padding: 5px 0px 0px -10px;
    margin: 0px 10px 0px 10px;
    font-size: 45px;
    transition: all 450ms;
}
.recommandRow #iconRight{
    margin: 10px;
    font-size: 25px;
    transition: all 450ms;
}
.recommandRow #iconRight:hover{
    padding: 5px -10px 0px 0px;
    margin: 0px 0px 0px 0px;
    font-size: 45px;
    transition: all 450ms;
}

.oneReProd{
    text-align: start;
    width: 20%;
    transition: all 500ms;
    border:0;
    animation-name: scale-up-center;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-delay: 0s;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: none;
    padding:5px;
    background: none;
}
.oneReProd img{
    box-shadow: 0px 0px 4px gray;
    animation-duration: 3s;
    width: 100%;
    height: 160px;
    padding: 0px;
    border-radius: 5px;
}
.oneReProd .LALA p{
    margin-left: 10px;
    font-size: 16px;
    display: -webkit-box;
    line-height:30px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden; 
}
.test_red{
    width: 100%;
    background-color: blue;
    /* padding:10px 0px; */
    /* display: inline-block; */
    /* text-align: left; */
}
.oneReProd h2{
    margin-top: -10px;
    margin-left: 10px;
    font-size: 14px;
}
.div_control_image{
    width: 100%;
    overflow: hidden;
    text-align: center;
}
.div_control_image .imge_product_story{
    width:150px ;
    height: 150px;
    text-align: center;
    border-radius: 5px;
    margin: 10px;
    box-shadow: 0px 0px 3px gray;
}
.oneReProd:hover .LALA p{
    color: rgb(3, 169, 245);
} 

@keyframes scale-up-center {
    0%{
      -webkit-transform: scale(.2);
      transform: scale(.2);
    }
    100%{
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .oneReProd:focus{
      outline: none;
  }
  @media (max-width: 1199px) { 
    .div_control_image .imge_product_story{
        width:100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .div_control_image{
        padding: 2px;
        margin: 0px;
    }
}
@media (max-width: 991px){
    .div_control_image .imge_product_story{
        width:100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .div_control_image{
        padding: 2px;
        margin: 0px;
    }
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
}