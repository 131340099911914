.image_category_left{
    width: 35px;
    transform: rotate(180deg);
    
}
.one_category_left{
    color: black;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
}
.one_category_left .nav-link{
    padding: 0px;
    margin: 4px 0px;
    }
.yaya{
    box-shadow: -1px -1px 5px rgba(128, 128, 128, 0.432);
    background-color:white;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    display: inline-block;
    text-align: center;
    height: 100%;
    padding:0% 10px 20px 10px;
    -webkit-writing-mode: vertical-lr;
    -moz-writing-mode: vertical-lr;
    -ms-writing-mode: vertical-lr;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
.left_tab_category .nav .active{
    background: none;
    text-decoration: underline;
    border-top:0px so0id gray;
    box-shadow: -0px 0px 5px gray;
    border-radius: 50px;
}
.left_tab_category .nav .active .image_category_left{
   width: 50px;
}
#btn_more_product_filter_category{
    border: 0px;
    padding:10px 200px;
    border:0px;
    margin:20px 0px;
}
.contorl_btn_get_more_or_not{
    text-align: center;
    margin: 30px 0px;
}
.title_tap{
    display: inline;
    text-align: left;
}
.title_tap p{
    margin: 40px 0px;
    font-size: 20px;
}

.not_data_filter_category{
    height: 100%;
}
.not_data_filter_category h1{
    position: absolute;
    bottom: 50%;
    left: 45%;
    font-size: 20px;
    text-align: center;
}
.loadingSmall_Category{
    position: absolute;
    bottom: 50%;
    left: 45%;
}

@media (max-width: 1199px) { 
    .yaya{
        padding:0% 5px 20px 5px;
    }
    .left_tab_category .nav .active .image_category_left{
        width: 40px;
    }
    #btn_more_product_filter_category{
        border: 0px;
        padding:10px 200px;
        border:0px;
        margin:20px 0px;
    }
    #loadingSmall_Category{
        padding: 0px !important;
    }
    .not_data_filter_category h1{
        left: 48%;
    }
    .loadingSmall_Category{
        left: 48%;
    }
}
@media (max-width: 991px){
    #btn_more_product_filter_category{
        border: 0px;
        padding:5px 100px;
        border:0px;
        margin:20px 0px;
        font-size: 14px;
    }
    .not_data_filter_category h1{
        left: 48%;
    }
    .loadingSmall_Category{
        left: 48%;
    }
}
@media (max-width: 767px){
    .not_data_filter_category h1{
        position: absolute;
        bottom: 50%;
        left: 48%;
        font-size: 16px;
        text-align: center;
    }
}
@media (max-width: 575px){
    #btn_more_product_filter_category{
        border: 0px;
        padding:5px 50px;
        border:0px;
        margin:20px 0px;
        font-size: 14px;
    }
    .not_data_filter_category h1{
        left: 52%;
    }
    .loadingSmall_Category{
        left: 52%;
    }
}