#yes_ComfirmPin{
    background-color: #ffeeb6fd;
    padding:10px 0px;
    width: 100%;
    color: black;
    border:none;
    border-radius: 5px;
}
#Yes_ComfirmPinDisable{
    padding:10px 0px;
    width: 100%;
    border:none;
    border-radius: 5px;
}
#No_ComfirmPin{
    padding:10px 0px;
    width: 100%;
    color: black;
    border:none;
    border-radius: 5px;
    background: none;
    color: crimson;
}
#No_ComfirmPin:focus,
#yes_ComfirmPin:focus{
    outline: none;
}
.model_foolter_comfirmPin{
    padding: 10px 0px 0px 0px;
}
.model_body_confirmPin{
    padding:20px 0px ;
}
.model_body_confirmPin h6{
    font-family: unset;
}
.total_comfirmPin{
    padding:50px 0px 10px 0px;
    text-align: center;
}
#img_logo_comfirmPin{
    width: 200px;
}
.total_comfirmPin h2{
    margin-top: 20px;
    color: crimson;
}

.mode_wrongPassword_confirmPin{
    background-color: rgba(97, 97, 97, 0.459);
}
.mode_wrongPassword_confirmPin{
    background-color: rgba(97, 97, 97, 0.459);
}
.mode_wrongPassword_confirmPin h1{
    font-size: 18px;
 }
 .mode_wrongPassword_confirmPin #btnHide{
    font-size: 18px;
 }
 
 .mode_wrongPassword_confirmPin span{
    font-size: 18px;
    color: red;
 }
 .model_body_verifyPass {
    padding: 0px 10px;
 }
 #anig_icon_error{
     font-size: 50px;
     color: red;
 }
 @media (max-width: 1199px) { 
    .mode_wrongPassword_confirmPin #btnHide{
        font-size: 16px;
     }
}
@media (max-width: 991px){
    .mode_wrongPassword_confirmPin #btnHide{
        font-size: 14px;
     }
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    #input_comfirm_pay_end{
        width: 100% !important;
    }
    .model_body_verifyPass .row .pincode-input-container{
        text-align: center;
        width: 100%;
        display: inline-block;
     }
     .model_body_verifyPass input{
         padding: 0px !important;
         margin: 1px !important;
         width: 40px !important;
         height: 40px !important;
     }
}
@media (max-width: 352px){
    .model_body_verifyPass input{
        width: 30px !important;
        height: 30px !important;
        border-radius: 15px !important;
    }
}