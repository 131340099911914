.NavigationNew{
    width: 100%;
    height: auto;
    /* background-color: var(--background); */
    position: fixed;
    top: 0px;
    z-index: 10;
    box-shadow: 0px 2px 5px wheat;
}
.navHaveSize{
    height: 70px;
}
.class_show_title_navigation{
    margin-right: 30px;
    margin-top: 10px;

}
.class_show_title_navigation p{
    border: 0px;
    font-style: 10px;
    margin-bottom: 0px;
}
#logoAMATAK{
    height: 80px;
    width: auto;
}
.links{
    font-family: var(--fontFamily_Kantumruy);
    font-size: 20px;
    padding:10px 30px;
    font-weight: 400;
}
.links:hover{
    padding-left: 30px;
    color: rgba(96, 100, 100,1);
    text-decoration: none;
}

#icon_Search , #icon_LocalMallOutlined ,#icon_PersonOutlineOutlined{
    font-size: 30px;
    margin:-10px 10px 0px 10px ;   
}

.boxSearch{
    padding: 50px 200px 30px 200px ;
}
#icon_Clear{
    font-weight: 100;
    font-size: 15px;
}


.fixedChat {
    position: fixed;
    bottom: 2%;
    right: 2%; 
    z-index: 19;
    
}
.fixedChat button{
    background-color: rgba(0, 255, 255, 0);
    border: 0px;
    padding: 10px;
    border-radius: 50%;
}
.fixedChat button:focus{
    outline: none;
}
.fixedChat #icon{
    width: 60px;
   /* color: white; */
    /* font-size: 40px; */
}
.container{
    max-width: 1200px;
}
.row,.col-12{
    padding: 0px;
    margin: 0px;
}
.oneLink_search{
    margin: 20px 0px;
    color: gray;
    font-size: 16px;
    line-height: 10px;
}
.search{
    color: black;
    margin: 20px 0px;
    font-size: 18px;
    line-height: 10px;
}
.search:hover,
.oneLink_search:hover{
    cursor: pointer;
}
.ShowallCategory{
    padding: 10px 0px;
}
.ShowallCategory a:hover{
    text-decoration: none;
}
#searchProd{
    padding: 10px;
    line-height: 40px;
}
.none_smallScren{
    display:flex;
    justify-content:left;
    align-items:center
    }
.icon_3_screen{
    display:flex;
    justify-content:left;
    align-items:center;
}

.hide_menu_screen_big{
    display: none;
}
.dons_t_show_icon_3{
        display: inline;
    }
#submenus{
    display: none;
}
.image-lang-icon{
    margin-left: 10px;
    width: 20px;
    height: 20px;
}
.MuiButton-root {
   min-width: 40px !important;
   padding: 0px 5px !important;
}
.MuiButton-contained{
    box-shadow: none !important;
}
/* ///??//////////// */
@media (max-width: 1199px) { 
    .NavigationNew{
        max-width: 100%;
        height: auto;
        position: fixed;
        top: 0px;
        z-index: 10;
        box-shadow: 0px 2px 5px wheat;
    }
    #logoAMATAK{
        height: 80px;
        width: auto;
     }
    .links{
        font-size: 18px;
        padding:10px 20px;
    }
    
    #icon_Search , #icon_LocalMallOutlined ,#icon_PersonOutlineOutlined{
        font-size: 25px;
        margin:0px 5px ;
        margin-top: -10px;
    }
    
    .boxSearch{
        padding: 50px 80px 30px 80px ;
    }
    #icon_Clear{
        font-weight: 100;
        font-size: 15px;
    }

    .fixedChat #icon{
        width: 40px;
     }
     #submenus{
        display: none;
    }
    .links:hover{
        padding:10px 20px;
    }
}
@media (max-width: 991px){
    
    #logoAMATAK{
        margin-left: -20px;
        height: 60px;
    }
    .links{
        font-size: 16px;
        padding:10px 10px;
    }
    
    #icon_Search , #icon_LocalMallOutlined ,#icon_PersonOutlineOutlined{
        font-size: 20px;
        margin:0px 5px ;
        margin-top: -10px;
    }
    
    .boxSearch{
        padding: 50px 80px 30px 80px ;
    }
    #icon_Clear{
        font-weight: 100;
        font-size: 15px;
    }

    .fixedChat #icon{
        width: 40px;
     }
    #submenus{
        display: none;
    }
    .links:hover{
        padding:10px 10px;
    }
}
@media (max-width: 767px){
    #btn-lang-small{
        min-width:80px !important;
        margin-right: -45px;
    }
    #status_got_to_acc{
        font-size: 16px;
        text-align: center;
        background-color: cornflowerblue;
        padding: 10px 0px;
        margin:10px 10px;
    }
    .bigSearch{
        display: none;
    }
    .none_smallScren{
        display: none;
    }
    .icon_3_screen{
        display:flex;
        justify-content: flex-end;
        align-items:center;
    }
    .dons_t_show_icon_3{
        display: none;
    }
    #icon_LocalMallOutlined {
        font-size: 25px;
        margin:0px 10px ;
        margin-top: 0px;
    }
    .hide_menu_screen_big{
        display: inline;
    }
    .hide_menu_screen_big #button_menus_show_small_screen{
        border: 0px;
        background: none;
        margin-right: -20px;
        margin-left: 10px;
    }
   
    #icon_button_submenuse{
        font-size: 35px;
    }
    .menu_Small{
        z-index: 99999999999;
        height: 100%;
        width: 300px;
        padding: 40px 0px 20px 10px;
    }
    .menu_Small p{
        display: inline;
        font-size: 20px;
        padding: 0px 20px;
    }
    .menu_Small a{
        text-decoration: none;
        color: black;
    }
    #btnCloseMenuSamll{
        border: 0px;
        background:0 0;
        float: left;
        margin-left: 0px;
    }
    .linK_small_sub_menus{
        display: block;
        font-size: 16px;
        font-family: var(--fontFamily_Kantumruy);
        line-height:40px;
        padding: 0px 10px;
        font-weight: 600;
    }
    #image_profile_user_sub_menuse{
        display: inline; 
        width: 70px;
        align-items: center;
        margin: 20px 0px;
        border-radius: 5px;
    }
    .user_account_sub_menus{
        display: block;
        border-bottom: 1px solid gray;
        margin: 20px 0px;
        width: 90%;
    }
    #submenus{
        display: inline;
    }
    #searchProd{
        padding: 10px;
        line-height: 40px;
    }
    #linkInFooter{
        margin-left: -30px;
    }
    .search_product_sub_menus{
        margin:20px 0px;
        margin-left: 10px;
        width: 100%;
    }
    #button_search_small{
        margin:10px 10px 0px -40px ;   
        padding-top: 5px;
        z-index: 11111;
        /* border-bottom: 1px solid black; */
    }
}
@media (max-width: 575px){
    #submenus{
        display: inline;
    }
    #linkInFooter{
        right: 10px;
        position: absolute;
        display: inline;
        margin-left: 0px;
    }
}