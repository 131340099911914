#img_logo_changePassword{
    width: 80px;
    display: inline-block;
    float: left;
}
.control_changePassword{
    padding:20px 10px;
    width: 100%;
}
.changepasword_div{
    text-align: center;
}
#showPassword{
    padding:10px;
    border: 0px;
    background: none;
    height: 20px;
    color: #4f5b6698;
  }
#iconCloseChangePassword{
    float: right;
}
.header_changePassword{
    width: 100%;
    padding:10px 20px;  
    display: inline-block;
}
.changepasword_div h1{
    font-size: 18px;
    margin-top: 30px;
}
.modal-content 
.changepasword_div 
.row 
.pincode-input-container{
    width: 100%;
    display: inline-block;
}
.modal-content 
.changepasword_div 
.row 
.pincode-input-container input{
    text-align: center;
    max-width: 50px;
    max-height: 50px;
    padding: 0px;
    border-radius:100% ;
    margin: 0px;
    font-size: 25px;
}
.div_btn_submit_chage_password{
    padding: 20px 0px;
    display: inline-block;
    width: 100%;
    text-align: center;
}
#btn_submit_chage_password_disable{
    border: 0px;
    width: 90%;
    background-color: #ffeeb6fd;
    box-shadow: 0px 0px 1px gray;
    padding:10px 0px;
}
 #btn_submit_chage_password{
    border: 0px;
    width: 90%;
    background-color: #ffeeb6fd;
    box-shadow: 0px 0px 1px gray;
    padding:10px 0px;
}
#btn_submit_chage_password:focus{
   outline: none;
}
#btnHide_change_password_error{
    color: red;
    border: 0px;
    padding:5px 10px;
    background: none;
    width: 100%;
}
@media (max-width: 1199px) { 
  
}
@media (max-width: 991px){
   
}
@media (max-width: 767px){
    
}
@media (max-width: 575px){
    .changepasword_div input{
        width: 40px !important;
        margin: 0px 1px !important;
        height: 40px !important;
        font-size: 16px !important;
        border-radius: 15px !important;
    }
}
@media (max-width: 352px){
    .changepasword_div input{
        width: 35px !important;
        height: 35px !important;
    }
}