* button{
    font-family: 'Kantumruy';
}
* button:focus{
    outline: none;
}
* a{
    color: black;
}


:root{
    --background_ss:#fff6e1;
    --background_oneCart:#ffeec3;
    --background: #fff0c0;
    --background_button:#ffd600;
    --error:red;
    --background_slip:#F5F5DC;
    --fontFamily_Kantumruy:Kantumruy;
    --fontFamily_Ork:'Odor Mean Chey';
}